import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IKpiPattern, IKpiPatterns } from "../kpiPatterns";

declare global {
    interface IStoreState {
        kpiPatterns: IKpiPatterns;
    }
}

const useStats = "kpiPatterns";
const isEqual = (a: IKpiPattern, b: IKpiPattern) => a.modificationDate == b.modificationDate && a.uniqueName == b.uniqueName && a.millNodeId == b.millNodeId;

const kpiPatterns = createSlice({
    name: "kpiPatterns",
    initialState: null as IKpiPatterns,
    reducers: {
        set: (state, { payload }: PayloadAction<{ kpiPatterns: IKpiPattern[]; millId: string; }>) => (
            Utils.slices.set({
                state,
                nextState: (
                    _.chain(payload.kpiPatterns)
                        .map((kpi) => ({
                            ...kpi,
                            millNodeId: payload.millId, // ajout du millNodeId pour la différenciation
                        }) as IKpiPattern)
                        .keyBy(({ id }) => id)
                        .value()
                ),
                isEqual,
                useStats,
            })
        ),
        patch: (state, { payload }: PayloadAction<IKpiPattern[]>) => (
            Utils.slices.patch({ state, nextState: _.keyBy(payload, ({ id }) => id), isEqual, useStats })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...kpiPatterns.actions,
};

export default {
    [kpiPatterns.name]: kpiPatterns.reducer,
};