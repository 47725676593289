import { ajaxRequest } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../../../constants";
import { FormulaType } from "./model";
import { actionsCreators } from "./slices";

/**
 * Validate formula. Cancel previous request, dans debounced ajax calls...
 */
const ajaxReq: ReturnType<typeof ajaxRequest> = null;
let timeout: number;
export const validateFormula = ((type: FormulaType, formula: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    if (ajaxReq) ajaxReq.request.abort(); // abort previous ajax call

    window.clearTimeout(timeout);
    timeout = window.setTimeout(async () => {
        const { kpiPatternEditor } = getState();
        if (!kpiPatternEditor) return;
        const { database, groups, collection, kpisInFormula } = kpiPatternEditor;
        await ajaxRequest({
            method: "POST",
            data: { formula, database, groups, collection, type, kpisInFormula },
            serverLessResolve: () => formula.includes("unknown") ? ["invalid"] : [],
            url: `${API_PREFIX_PRODUCTION_MANAGER}/kpi-patterns/validate`,
            onSuccess: ((remoteErrors: string[]) => {
                if (_.isEmpty(remoteErrors)) {
                    dispatch(actionsCreators.deleteRemote(type));
                } else {
                    dispatch(actionsCreators.setRemote({ type, errors: remoteErrors }));
                }
            }),
        }).promise;
    }, 750);
});