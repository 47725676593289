import { Cache, store, t } from "@comact/crc";
import _ from "lodash";
import moment from "moment";
import { create as createSeeder } from "random-seed";
import { fetchTemplates } from "../nodeTemplate/mocks";
import { IConnexionStatuses, INode, connexionStatuses, createNewNode, machineModels } from "./model";

const rand = createSeeder();

export const fetchAllNodes = () => {
    const initialNodes = _.keyBy(getAllNodes(), ({ id }) => id);
    const currentNodes = store.getState().nodes;
    const mixedNodes = { ...initialNodes, ...currentNodes }; // override initial node with modified current nodes
    return _.values(mixedNodes);
};

export const fetchNode = (id: string) => fetchAllNodes().find((n) => n.id == id);

export const getAllNodes = (): INode[] => (
    Cache.remember("getAllNodes", () => {
        rand.seed("getAllNodes");
        const nodes: INode[] = [];

        const templates = fetchTemplates();

        const modificationDate = (new Date(rand.intBetween((new Date("2020-01-01")).getTime(), (new Date("2022-01-01")).getTime()))).getTime(); // Date between 2020 and 2022

        if (process.env.EXEC_MODE == "icp") {
            const customerTemplate = templates.find(({ name }) => name == "customer");

            const customerNode = createNewNode({
                id: "customer",
                name: "customer",
                templateName: "customer",
                templateId: customerTemplate.id,
                modificationDate,
            });
            nodes.push(customerNode);

            const locationTemplate = templates.find(({ name }) => name == "location");

            const locationNode = createNewNode({
                id: "location",
                name: "location",
                templateName: "location",
                templateId: locationTemplate.id,
                parentId: _.find(nodes, ({ templateName }) => templateName == "customer").id,
                modificationDate,
            });
            nodes.push(locationNode);
        }

        _.times(process.env.EXEC_MODE == "icp" ? 2 : 1, (idx) => {
            const millTemplate = templates.find(({ name }) => name == "millCodec");

            const millNode = createNewNode({
                id: "mill" + idx,
                name: "Mill" + idx,
                templateName: "millCodec",
                templateId: millTemplate.id,
                parentId: process.env.EXEC_MODE == "icp" ? _.find(nodes, ({ templateName }) => templateName == "location").id : null,
                modificationDate,
            });
            nodes.push(millNode);

            const iepTemplate = templates.find(({ name }) => name == "iep");

            const iepNode = createNewNode({
                id: "iep" + idx,
                name: "IEP",
                templateName: "iep",
                templateId: iepTemplate.id,
                parentId: millNode.id,
                modificationDate,
                host: "iep.comact.com",
                version: "1.0.0",
                cmocIdentifier: "cmoc-iep",
            });
            nodes.push(iepNode);

            const machineComponentTemplate = templates.find(({ name }) => name == "machineComponent");

            const cloudAgentNode = createNewNode({
                id: "cloudAgentNode" + idx,
                name: "Cloud Agent",
                templateName: "machineComponent",
                templateId: machineComponentTemplate.id,
                parentId: iepNode.id,
                modificationDate,
            });
            nodes.push(cloudAgentNode);

            const machineTemplate = templates.find(({ name }) => name == "machineCodec");
            const scannerTemplate = templates.find(({ name }) => name == "scannerCodec");
            _.forEach(machineModels, (machineModel) => {
                const machineNode = createNewNode({
                    id: `machine-${machineModel}` + idx,
                    name: t(`machines.name.${machineModel}`),
                    templateName: "machineCodec",
                    templateId: machineTemplate.id,
                    parentId: millNode.id,
                    modificationDate,
                    acquisitionMinDate: null,
                    machine: machineModel,
                    liveConfigs: null,
                    host: null,
                    productionStatus: null,
                    simulationConfigs: null,
                    minGap: 0,
                    maxGap: 2,
                    recommendedGap: 1,
                    version: "1.0.0",
                    cmocIdentifier: `cmoc-${machineModel}`,
                });
                nodes.push(machineNode);

                nodes.push(createNewNode({
                    id: `scanner-${machineModel}-0` + idx,
                    name: "Scanner",
                    templateName: "scannerCodec",
                    templateId: scannerTemplate.id,
                    parentId: machineNode.id,
                    modificationDate,
                    machine: machineModel,
                    scannerIndex: "0",
                }));
            });

            const cameraServerTemplate = templates.find(({ name }) => name == "cameraServer");

            const cameraServerNode = createNewNode({
                id: "camera-server" + idx,
                name: "Camera-Server",
                templateName: "cameraServer",
                templateId: cameraServerTemplate.id,
                parentId: millNode.id,
                modificationDate,
                host: "127.0.0.1",
                password: "",
                username: "",
            });
            nodes.push(cameraServerNode);

            const cameraTemplate = templates.find(({ name }) => name == "camera");
            nodes.push(createNewNode({
                id: "camera1" + idx,
                name: "Camera 1",
                templateName: "camera",
                templateId: cameraTemplate.id,
                parentId: cameraServerNode.id,
                modificationDate,
                audioEnabled: false,
                internalCameraId: "0d767e49-a3c0-6472-6182-31d9e76fa073",
                status: "Recording",
            }));

            const smartVisionZoneTemplate = templates.find(({ name }) => name == "smartVisionZone");
            nodes.push(createNewNode({
                id: "smartVisionZone1" + idx,
                name: "Smart Vision Zone 1",
                templateName: "smartVisionZone",
                templateId: smartVisionZoneTemplate.id,
                parentId: millNode.id,
                modificationDate,
                host: "10.1.37.53",
                port: 8080,
            }));

            const pickAndPlaceTemplate = templates.find(({ name }) => name == "pickAndPlace");
            nodes.push(createNewNode({
                id: "pickAndPlace" + idx,
                name: "Pick and place",
                templateName: "pickAndPlace",
                templateId: pickAndPlaceTemplate.id,
                parentId: millNode.id,
                modificationDate,
            }));
        });

        return nodes;
    })
);

export const getAllAllConnexionStatuses = (): IConnexionStatuses => {
    const nodes = fetchAllNodes();
    return _.mapValues(_.keyBy(nodes, (node) => node.id), (node) => {
        rand.seed("getAllAllConnexionStatuses" + node.id);
        return {
            status: connexionStatuses[rand.intBetween(0, _.size(connexionStatuses) - 1)],
            lastStatusUpdatedTimestamp: rand.intBetween(moment().startOf("hour").valueOf(), moment().valueOf()),
        };
    });
};