import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer";
import _ from "lodash";
import { convertNodesFromServer, IConnexionStatuses, INode, INodes, INodeServer } from "./model";

declare global {
    interface IStoreState {
        nodes: INodes;
        nodeConnexionStatuses: IConnexionStatuses;
        currentNodeId: string;
        currentMillNodeId: string;
        currentLocationNodeId: string;
    }
}

/**
 * Verifies if two nodes are equals. Nodes are equals if the modification date, the codec node state and the node health are equals.
 * @param a the first node
 * @param b the second node
 * @returns true if these two nodes are equals, false otherwise.
 */
const isEqual = (a: INode, b: INode): boolean => (
    a.modificationDate == b.modificationDate && a.nodeState == b.nodeState && a.desiredMetadata?.lastUpdated == b.desiredMetadata?.lastUpdated
);

const getNodesRecursively = (state: WritableDraft<INodes>, from: string): string[] => {
    const childrenNodeIds = _.filter(state, (n) => n.parentId == from).map((n) => n.id);

    if (childrenNodeIds.length == 0) return [from];

    let result: string[] = [from];
    childrenNodeIds.forEach((id) => {
        result = _.concat(result, getNodesRecursively(state, id));
    });

    return result;
};

const useStatsNodes = "nodes";

const nodes = createSlice({
    name: "nodes",
    initialState: null as INodes,
    reducers: {
        set: (state, { payload }: PayloadAction<INodeServer[]>) => (
            Utils.slices.set({ state, nextState: convertNodesFromServer(payload), isEqual, useStats: useStatsNodes })
        ),
        patch: (state, { payload }: PayloadAction<INodeServer[]>) => (
            Utils.slices.patch({ state, nextState: convertNodesFromServer(payload), isEqual, useStats: useStatsNodes })
        ),
        deleteRecursive: (state, { payload }: PayloadAction<string>) => ( // The payload is the id of the node on which to start the recursive delete
            Utils.slices.delete({ state, keys: getNodesRecursively(state, payload), useStats: useStatsNodes })
        ),
    },
});

const nodeConnexionStatuses = createSlice({
    name: "nodeConnexionStatuses",
    initialState: {} as IConnexionStatuses,
    reducers: {
        setNodeConnexionStatuses: (state, { payload }: PayloadAction<IConnexionStatuses>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: payload, useStats: "nodeConnexionStatuses" })
        ),
    },
});

const currentNodeId = createSlice({
    name: "currentNodeId",
    initialState: null as string,
    reducers: {
        setCurrentNodeId: (_state, { payload }: PayloadAction<string>) => payload,
    },
});

const currentMillNodeId = createSlice({
    name: "currentMillNodeId",
    initialState: null as string,
    reducers: {
        setCurrentMillNodeId: (state, { payload }: PayloadAction<string>) => {
            if (state == payload) {
                console.error("PageNode should not change if same...", state, "vs", payload);
            }
            return payload;
        },
    },
});

const currentLocationNodeId = createSlice({
    name: "currentLocationNodeId",
    initialState: null as string,
    reducers: {
        setCurrentLocationNodeId: (state, { payload }: PayloadAction<string>) => {
            if (state == payload) {
                console.error("PageNode should not change if same...", state, "vs", payload);
            }
            return payload;
        },
    },
});

export const actionsCreators = {
    ...nodes.actions,
    ...nodeConnexionStatuses.actions,
    ...currentNodeId.actions,
    ...currentMillNodeId.actions,
    ...currentLocationNodeId.actions,
};

export default {
    [nodes.name]: nodes.reducer,
    [nodeConnexionStatuses.name]: nodeConnexionStatuses.reducer,
    [currentNodeId.name]: currentNodeId.reducer,
    [currentMillNodeId.name]: currentMillNodeId.reducer,
    [currentLocationNodeId.name]: currentLocationNodeId.reducer,
};