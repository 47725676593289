import { getLocalizedText } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { INodes } from "../../node";
import { getNodeNamePath } from "../../node/selectors";
import { createKpiLabel } from "../kpiDefinitions";
import { IKpiPatternWithDefinitions } from "../kpiDefinitions/model";
import { IKpiQueryRecipes, IKpiQueryRecipesNode } from "../kpiQueries";
import { IKpiPattern, IKpiPatterns, createKpiDefinitionUniqueId, createKpiPatternOptionValue, createKpiRecipeOptionValue } from "./model";

interface IKpiFilters {
    showKpisOfKpis?: boolean;
    showKpiCollections?: boolean;
    nodeIdsToFilter?: string[];
}

export const getKpiPatterns = memoize(({ state, filters = { showKpiCollections: true, showKpisOfKpis: true, nodeIdsToFilter: [] } }: { state: IStoreState; filters?: IKpiFilters; }): IKpiPatterns => (
    state.kpiPatterns
        ? _.chain(state.kpiPatterns)
            .filter((kpiPattern) => (
                (_.isEmpty(filters.nodeIdsToFilter) || _.some(kpiPattern.nodeIds, (nodeId) => _.includes(filters.nodeIdsToFilter, nodeId))) &&
                (filters?.showKpisOfKpis || kpiPattern.database != "kpi") &&
                (filters?.showKpiCollections || !kpiPattern.collection)
            ))
            .keyBy(({ id }) => id)
            .value()
        : null
));

export const getKpiPatternsWithDefinitions = memoize(({ state, filters = { showKpisOfKpis: true, nodeIdsToFilter: [] } }:
{
    state: IStoreState;
    filters?: IKpiFilters;
}) => (
    _.reduce(getKpiPatterns({ state, filters }), (tempKPIs, kpiPattern) => {
        tempKPIs[kpiPattern.id] = kpiPattern;
        if (kpiPattern.collection) {
            _.forEach(kpiPattern.kpiDefinitions, ({ id: definitionId }) => {
                if (definitionId == "default") return; // For the kpi collections, the default definition is not actually a definition, it's a template for the other definitions generated
                const defUniqueId = createKpiDefinitionUniqueId(kpiPattern.uniqueName, definitionId, kpiPattern.collection);
                tempKPIs[defUniqueId] = ({
                    ...kpiPattern,
                    id: defUniqueId,
                    uniqueName: defUniqueId,
                    kpiDefinitions: null,
                    collection: false,
                    definitionTitle: kpiPattern.kpiDefinitions[definitionId].title,
                    isDefinition: true,
                });
            });
        }
        return tempKPIs;
    }, {} as IKpiPatternWithDefinitions)
));

export const getKpiPatternById = (state: IStoreState, id: string): IKpiPattern => getKpiPatterns({ state })?.[id];

export const getKpiPatternByKey = (state: IStoreState, key: string): IKpiPattern => _.find(getKpiPatterns({ state }), (k) => k.uniqueName == key);

export const makeKpiPatternByKeySelector = () => memoize(({ state: { kpiPatterns }, uniqueName }: { state: IStoreState; uniqueName: string; }) => (
    _.find(kpiPatterns, (pattern) => pattern.uniqueName == uniqueName)
));

export const getNodesAvailableInKpiPatterns = memoize(<T extends (IKpiPattern | IKpiPatternWithDefinitions)>({ kpiPatterns, nodes }: { kpiPatterns: T[]; nodes: INodes; }) => (
    _.reduce(kpiPatterns, (acc, k) => {
        _.forEach(k.nodeIds, (nId) => {
            if (nodes?.[nId]) acc[nId] = nodes[nId]; // KPI patterns are not being refreshed at the same frequency as the nodes and some of the nodes might be missing
        });
        return acc;
    }, {} as INodes)
));

export const makeNodeNamesAvailable = () => memoize(({ state, nodeIds }: { state: IStoreState; nodeIds: string[]; }) => (
    _.map(nodeIds, (nodeId) => {
        const node = state.nodes?.[nodeId];
        if (!node) return nodeId;
        return getNodeNamePath(state, node.id);
    })
));

export interface IOptionKpi {
    label: string;
    sortValue?: string;
    value: string;
    data: IKpiQueryRecipes["kpis"][number];
    isCollection: boolean;
    isError: boolean;
}

/**
 * showCollections: shows the kpi collections
 * showKpisOfKpis: shows database="kpi"
 * showKpiDefinitions: generates definition options for kpi collections
 */
export const getKpisOptions = memoize(({
    state,
    selectedKpis,
    selectedNodes,
    showCollections = true,
    showKpisOfKpis = true,
    showKpiDefinitions = true,
}: {
    state: IStoreState;
    selectedNodes: IKpiQueryRecipesNode[];
    selectedKpis: IKpiQueryRecipes["kpis"];
    showCollections?: boolean;
    showKpisOfKpis?: boolean;
    showKpiDefinitions?: boolean;
}) => {
    const options = (
        _.chain(state.kpiPatterns)
            .reduce((tempOptions, kpiPattern) => {
                const isValid = _.some(selectedNodes, (s) => _.includes(kpiPattern.nodeIds, s.id));
                if (kpiPattern.collection) {
                    if (showKpiDefinitions) {
                        _.forEach(kpiPattern.kpiDefinitions, ({ id: definitionId }) => {
                            if (definitionId != "default") { // For the kpi collections, the default definition is not actually a definition, it's a template for the other definitions generated
                                const value = createKpiPatternOptionValue(kpiPattern.uniqueName, definitionId);
                                const isSelected = _.some(selectedKpis, (k) => createKpiRecipeOptionValue(k) == value); // Could be selected but not valid for selected nodes
                                if (isValid || isSelected) {
                                    tempOptions.push({
                                        label: createKpiLabel(state.mUnits, kpiPattern, definitionId),
                                        value: createKpiPatternOptionValue(kpiPattern.uniqueName, definitionId),
                                        data: { type: "kpiDefinition", patternKey: kpiPattern.uniqueName, definitionId },
                                        isCollection: false,
                                        isError: !isValid,
                                    });
                                }
                            }
                        });
                    }
                    if (showCollections) {
                        const value = createKpiPatternOptionValue(kpiPattern.uniqueName);
                        const isSelected = _.some(selectedKpis, (k) => createKpiRecipeOptionValue(k) == value); // Could be selected but not valid for selected nodes
                        if (isValid || isSelected) {
                            tempOptions.push({
                                label: createKpiLabel(state.mUnits, kpiPattern, "default"),
                                sortValue: getLocalizedText(kpiPattern.title),
                                value,
                                data: { type: "kpiGroup", patternKey: kpiPattern.uniqueName, definitionId: null },
                                isCollection: true,
                                isError: !isValid,
                            });
                        }
                    }
                } else if (showKpisOfKpis || kpiPattern.database != "kpi") { // Kpi that is not a collection and is the correct type
                    const definitionId = _.values(kpiPattern.kpiDefinitions)[0].id; // Since it's not a collection there's always only one kpiDefinition
                    const value = createKpiPatternOptionValue(kpiPattern.uniqueName, definitionId);
                    const isSelected = _.some(selectedKpis, (k) => createKpiRecipeOptionValue(k) == value); // Could be selected but not valid for selected nodes
                    if (isValid || isSelected) {
                        tempOptions.push({
                            label: createKpiLabel(state.mUnits, kpiPattern, definitionId),
                            value,
                            data: { type: "kpiDefinition", patternKey: kpiPattern.uniqueName, definitionId },
                            isCollection: false,
                            isError: !isValid,
                        });
                    }
                }
                return tempOptions;
            }, [] as IOptionKpi[])
            .orderBy(({ sortValue, label }) => sortValue || label)
            .value()
    );

    // Missing options could happen if a kpi is not existing anymore, for example on a migrated dashboard
    const missingOptions = _.reduce(selectedKpis, (missing, { patternKey, definitionId, type }) => {
        const matchingOption = _.find(options, (o) => definitionId == o.data.definitionId && patternKey == o.data.patternKey && type == o.data.type);
        const value = createKpiPatternOptionValue(patternKey, definitionId);
        if (!matchingOption) {
            missing.push({
                label: `?${value}`,
                value,
                data: { type, patternKey, definitionId },
                isCollection: false,
                isError: true,
            });
        }
        return missing;
    }, [] as IOptionKpi[]);

    return [...missingOptions, ...options];
});
export const getAvailableDatabases = memoize(({ kpiPatterns }: IStoreState) => (
    _.chain(kpiPatterns)
        .map(({ database }) => database)
        .uniq()
        .orderBy(((value) => value.toLocaleLowerCase())) // To place the option "No DataType" at the beginning
        .value()
));
