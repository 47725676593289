import _ from "lodash";
import { IReportTemplate } from "./model";

export const getReportTemplates = (): IReportTemplate[] => (
    [{
        id: "RTA_DowngradeProductGraph",
        multiMachine: false,
        parameters: [{
            name: "ScanMode",
            value: "",
            parent: "",
            cascading: false,
            choices: [],
            group: "",
            multiSelect: false,
            type: "String",
            valueClass: "",
            visible: true,
            required: false,
            isDynamic: false,
            label: {
                values: {
                    en: "Scan Mode",
                    fr: "Mode d'entrée",
                    pt: "Modo scan",
                    fi: "Skannausmoodi",
                },
            },
        },
        {
            name: "DynamicParameter",
            value: null,
            cascading: false,
            choices: null,
            parent: "",
            group: "",
            multiSelect: false,
            type: "Integer",
            valueClass: "",
            visible: true,
            required: false,
            isDynamic: true,
            label: {
                values: {
                    en: "Dynamic Parameter",
                    fr: "Paramètre dynamique",
                    pt: "Parâmetro dinâmico",
                    fi: "Dynaaminen parametri",
                },
            },
        },
        {
            name: "CascadingParameterParent",
            value: null,
            cascading: true,
            parent: "",
            choices: null,
            group: "",
            multiSelect: false,
            type: "Integer",
            valueClass: "",
            visible: true,
            required: false,
            isDynamic: true,
            label: {
                values: {
                    en: "Cascading Parameter Parent",
                    fr: "Paramètre parent en cascade",
                    pt: "Parâmetro pai em cascata",
                    fi: "Kaskadin parametri vanhempi",
                },
            },
        }],
        groups: [],
    },
    {
        id: "RTA_LinearLiveTallyBucking",
        multiMachine: false,
        parameters: [{
            name: "ScanMode",
            parent: "",
            value: "",
            cascading: true,
            choices: [
                {
                    label: {
                        values: {
                            en: "Cascading Parameter choice 1",
                            fr: "Paramètre parent en cascade choix 1",
                            pt: "",
                            fi: "",
                        },
                    },
                    value: 1,
                },
                {
                    label: {
                        values: {
                            en: "Cascading Parameter choice 2",
                            fr: "Paramètre parent en cascade choix 2",
                            pt: "",
                            fi: "",
                        },
                    },
                    value: 2,
                },
            ],
            group: "",
            multiSelect: false,
            type: "String",
            valueClass: "",
            visible: true,
            required: false,
            isDynamic: false,
            label: {
                values: {
                    en: "Scan Mode",
                    fr: "Mode d'entrée",
                    pt: "Modo scan",
                    fi: "Skannausmoodi",
                },
            },
        }],
        groups: [],
    },
    {
        id: "RTA_DowngradeProductGraph",
        multiMachine: false,
        parameters: [{
            name: "ScanMode",
            parent: "",
            value: "",
            cascading: false,
            choices: [],
            group: "",
            multiSelect: false,
            type: "String",
            valueClass: "",
            visible: true,
            required: false,
            isDynamic: false,
            label: {
                values: {
                    en: "Scan Mode",
                    fr: "Mode d'entrée",
                    pt: "Modo scan",
                    fi: "Skannausmoodi",
                },
            },
        }],
        groups: [],
    }]
);

export const getDynamicReportParameterChoices = (templateId: string) => (
    _.chain(_.find(getReportTemplates(), ({ id }) => id == templateId).parameters)
        .filter(({ isDynamic }) => isDynamic)
        .flatMap(({ choices }) => choices)
        .value()
);