import { ILocalizedString } from "@comact/crc";
import { IKpiZoneValue } from "../kpiObjectives/model";

export const limitTypes = ["greater", "less", "equal-or-greater", "equal-or-less", "equal-to", "not-equal-to", "inside-range", "outside-range"] as const;
export type ILimitType = typeof limitTypes[number];

export const alertConditionTypes = ["limit", "zone"] as const;
export type IAlertConditionType = typeof alertConditionTypes[number];

export type IAlertCondition = IAlertConditionLimit | IAlertConditionZone;

interface IAlertConditionAbstract {
    patternKey: string;
    definitionId: string;
    type: IAlertConditionType;
}

export interface IAlertConditionLimit extends IAlertConditionAbstract {
    type: "limit";
    limitType: ILimitType;
    param1: number;
    param2?: number; // for inside-range and outside-range
}

export interface IAlertConditionZone extends IAlertConditionAbstract {
    type: "zone";
    kpiZones: IKpiZoneValue[];
}

export const alertPriorities = ["low", "normal", "high", "emergency"] as const;
export type IAlertPrioritiesType = typeof alertPriorities[number];

export const alertSeverities = ["trivial", "normal", "major", "blocker", "critical"] as const;
export type IAlertSeveritiesType = typeof alertSeverities[number];

export type PlcSeverityValue = 1 | 2 | 3;
export const plcSeverities = { INFO: 1, WARNING: 2, ERROR: 3 } as { [key: string]: PlcSeverityValue; };

export const alertHandlerTypes = ["alarm", "email", "plc"] as const;
export type IAlertHandlerType = typeof alertHandlerTypes[number];

export const conditionCombinationStrategies = ["ANY_CONDITIONS_TRIGGERED", "ALL_CONDITIONS_TRIGGERED"] as const;
export type IConditionCombinationStrategiesType = typeof conditionCombinationStrategies[number];

export type IKpiAlertHandler = IEmailKpiAlertHandler | IAlertKpiAlertHandler | IPlcKpiAlertHandler;

export interface IEmailKpiAlertHandler {
    type: "email";
    subject: ILocalizedString;
    message: ILocalizedString;
    recipients: string[];
}

export interface IAlertKpiAlertHandler {
    type: "alarm";
    alarmTitle: ILocalizedString;
    alarmMessage: ILocalizedString;
    priority: IAlertPrioritiesType;
    severity: IAlertSeveritiesType;
}

export interface IPlcKpiAlertHandler {
    type: "plc";
    index?: number;
    message: ILocalizedString;
    severity: PlcSeverityValue;
}

export interface IKpiAlert {
    id: string;
    name: ILocalizedString;
    enabled: boolean;
    system: boolean;
    contextId: string; // id of data set context
    nodeId: string;
    delay: number; // number of seconds the condition needs to be met before triggering the alert
    cooldown: number; // number of seconds until the alert can trigger again
    combinationStrategy: IConditionCombinationStrategiesType; // condition logic
    conditions: IAlertCondition[]; // condition to trigger this alert
    handlers: IKpiAlertHandler[]; // which handle do we invoke when the alert is triggered
}

export type IKpiAlerts = Record<string, IKpiAlert>;

export const limitConditionTypeHasTwoParams = (type: ILimitType) => (["inside-range", "outside-range"] as ILimitType[]).includes(type);

export const createNewKpiAlert = (partialData: (Partial<Omit<IKpiAlert, "id">>) = {}): IKpiAlert => ({
    id: null, // will be set in the back
    name: { values: {} },
    enabled: true,
    system: false,
    contextId: null,
    nodeId: null,
    delay: 30,
    cooldown: 300,
    combinationStrategy: "ALL_CONDITIONS_TRIGGERED",
    conditions: [createNewCondition()],
    handlers: [createNewHandler()], // alerte est toujours là
    ...partialData,
});

export const createNewCondition = (conditionType: IAlertConditionType = "zone"): IAlertCondition => {
    switch (conditionType) {
        case "zone":
            return {
                patternKey: null,
                definitionId: null,
                type: "zone",
                kpiZones: [],
            };
        case "limit":
            return {
                patternKey: null,
                definitionId: null,
                type: "limit",
                limitType: null,
                param1: null,
                param2: null,
            };
    }
};

export const createNewHandler = (handlerType: IAlertHandlerType = "alarm"): IKpiAlertHandler => {
    switch (handlerType) {
        case "alarm":
            return {
                type: "alarm",
                alarmTitle: {
                    values: {},
                },
                alarmMessage: {
                    values: {},
                },
                priority: "normal",
                severity: "normal",
            };
        case "email":
            return {
                type: "email",
                subject: {
                    values: {},
                },
                message: {
                    values: {},
                },
                recipients: [],
            };
        case "plc":
            return {
                type: "plc",
                index: null,
                message: {
                    values: {},
                },
                severity: null,
            };
    }
};