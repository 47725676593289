import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IKpiDataIO, IKpiDataIOs } from "./model";

declare global {
    interface IStoreState {
        kpiDataIOs: IKpiDataIOs;
    }
}

const useStats = "kpiDataIOs";

const kpiDataIOs = createSlice({
    name: "kpiDataIOs",
    initialState: {} as IKpiDataIOs,
    reducers: {
        set: (state, { payload }: PayloadAction<IKpiDataIO[]>) => (
            Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(payload, ({ id }) => id), useStats })
        ),
        patch: (state, { payload }: PayloadAction<{ kpiDataIOs: IKpiDataIO[]; machineNodeId: string; }>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload.kpiDataIOs, ({ id }) => id),
                useStats,
                filterDataToEdit: (kpiDataIO: IKpiDataIO) => kpiDataIO.machineNodeId == payload.machineNodeId,
            })
        ),
        deleteAll: (state) => (
            Utils.slices.deleteAll({ state, useStats })
        ),
    },
});

export const actionsCreators = {
    ...kpiDataIOs.actions,
};

export default {
    [kpiDataIOs.name]: kpiDataIOs.reducer,
};
