import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

export const contextTypes = ["BATCH", "SHIFT", "PERIOD", "NUMBER"] as const;

export interface IContextDefinition {
    id: string;
    type: typeof contextTypes[number];
    name: ILocalizedString;
    length: number;
    unit: "DAYS" | "HOURS" | "MINUTES" | "SECONDS";
    limit: number; // for "number of pieces" contexts
    refreshRate: number; // how fast do the backend refresh the data, could be used to know how fast to call the api
    refreshRateUnit: "MINUTES" | "SECONDS";
    modificationStamp: number; // last modification of the data set context definition
    refreshStamp: number; // last time this context definition was refreshed
    systemDashboardDefault: boolean; // used when a system dashboards is generated
    uniqueReferenceName: string; // used to link a dashboard to a distinct context
    systemGenerated: boolean; // indicates if the context is provided as a default non deletable
    avgRefreshTime: number;
    millNodeId?: string;
}

export type IContextDefinitions = { [definitionId: string]: IContextDefinition; };

export const generateDefaultContextDefinition = (partialData?: Partial<Omit<IContextDefinition, "modificationStamp">>): IContextDefinition => ({
    id: _.uniqueId("new_"),
    type: "PERIOD",
    name: { values: { en: "", fr: "", pt: "", fi: "" } },
    length: 60,
    unit: "SECONDS",
    limit: 0,
    refreshRate: 60,
    refreshRateUnit: "SECONDS",
    modificationStamp: null, // the back will set it
    refreshStamp: 0,
    systemDashboardDefault: false,
    uniqueReferenceName: null, // assigned by the back end
    systemGenerated: false, // in this case it is created by the user
    avgRefreshTime: 0,
    ...partialData,
});
