import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocuments } from "./model";

declare global {
    interface IStoreState {
        documents: IDocuments;
    }
}

const useStatsDocuments = "documents";

const documents = createSlice({
    name: "documents",
    initialState: null as IDocuments,
    reducers: {
        set: (state, { payload }: PayloadAction<IDocuments>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: payload,
                useStats: useStatsDocuments,
            })
        ),
    },
});

export const actionsCreators = {
    ...documents.actions,
};

export default {
    [documents.name]: documents.reducer,
};