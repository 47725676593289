import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFeatureFlagsPerOrganization } from "./model";

declare global {
    interface IStoreState {
        featureFlags: IFeatureFlagsPerOrganization;
    }
}

const featureFlags = createSlice({
    name: "featureFlags",
    initialState: null as IFeatureFlagsPerOrganization,
    reducers: {
        set: (state, { payload }: PayloadAction<IFeatureFlagsPerOrganization>) => (
            Utils.slices.deepImmerUpdate(state, payload)
        ),
    },
});

export const actionsCreators = {
    ...featureFlags.actions,
};

export default {
    [featureFlags.name]: featureFlags.reducer,
};