import { app, useMapState } from "@comact/crc";
import { CSS, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import * as ReactDOM from "react-dom";
import tinycolor from "tinycolor2";
import { INodeStateStyle, NodesModule } from "..";
import { usePageNodeContext } from "../hooks";
import { getNodePerspectiveOrFallback, IPerspective } from "../perspectives/model";
import Status from "./Status";

export const HeaderBreadcrumb = React.memo(() => {
    const { nodeId, perspective } = usePageNodeContext();
    const element = React.useMemo(() => document.getElementById("headerBreadcrumb"), []); // empty container in CRC
    if (!element) return null;
    return (
        ReactDOM.createPortal(
            <Breadcrumb nodeId={nodeId} perspective={perspective} />,
            element
        )
    );
});

const BreadcrumbStyled = styled.div`
    #mainHeader .pageNameNodes:hover & {
        > a {
            color: ${tinycolor(CSS.colors.greyLight).lighten(15).toRgbString()};
            transition: color .8s ease-out;
            &:hover {
                transition: color .1s ease-out;
                text-decoration: none;
                color: ${CSS.colors.white};
            }
        }
    }
    display: flex;
    align-items: center;
    > div {
        margin: auto 3px;
        user-select: none;
        font-weight: ${CSS.fontWeight.light};
        color: ${CSS.colors.greyLight};
    }
    > a {
        color: ${CSS.colors.greyLight};
    }
    > .current {
        color: white;
        font-weight: ${CSS.fontWeight.bold};
    }
    > i {
        margin-left: 5px;
    }
`;

interface IBreadcrumbItem {
    label: string;
    url: string;
    folded: boolean;
}

const STATUS_TO_SHOW = ["error", "warn", "ok", "success"] as INodeStateStyle[];

const Breadcrumb = React.memo<{ nodeId: string; perspective: IPerspective; }>(({ nodeId, perspective }) => {
    const nodes = useMapState((state) => NodesModule.selectors.getAllNodes(state));

    const items = React.useMemo(() => {
        let links: IBreadcrumbItem[] = [];
        let newParentId: string = nodeId;
        while (newParentId && nodes[newParentId]) {
            const node = nodes[newParentId];
            const nodePerspective: IPerspective = getNodePerspectiveOrFallback(node.templateName, perspective);
            links = links.concat({ url: `/${nodePerspective}/${newParentId}`, label: node.name, folded: false });
            newParentId = nodes[newParentId] ? nodes[newParentId].parentId : null;
        }
        links = _.reverse(links);

        let charactersCount = null;
        while (charactersCount == null || charactersCount > 50) { // remove link label until the total of characters showed is less than the limit
            if (charactersCount != null) {
                const firstUnfoldLinkIndex = _.findIndex(links, (l) => !l.folded);
                links[firstUnfoldLinkIndex].folded = true;
            }
            charactersCount = _.size(_.reduce(links, (acc, i) => i.folded ? "..." : acc + i.label + " / ", ""));
        }
        return links;
    }, [nodes, nodeId, perspective]);

    if (_.isEmpty(items)) return null;

    return (
        <BreadcrumbStyled>
            {_.map(items, (item, index, array) => (
                <React.Fragment key={`item_${index}`}>
                    {index != _.size(array) - 1
                        ? (
                            <>
                                <a title={(item.folded && item.label) ? item.label : null} href={app.createUrl(item.url)}>{item.folded ? "…" : item.label}</a>
                                <div>/</div>
                            </>
                        )
                        : (
                            <span className="current">{item.label}</span>
                        )}
                </React.Fragment>
            ))}
            <Status id={nodeId} design="mini" statusToShow={STATUS_TO_SHOW} />
        </BreadcrumbStyled>
    );
});
