export interface INotificationGroup {
    id: string;
    name: string;
    alertIds: {
        grafana?: string[];
        // reports?: string[]; // To do : add when implemented later
        // kpiAlerts?: string[]; // To do : add when implemented later
    };
    userIds: string[];
    modificationDate: number;
    shiftDefinitionIds: string[];
}

export type INotificationGroups = Record<string, INotificationGroup>;

export const createNotificationGroup = (notificationGroup?: Partial<INotificationGroup>): INotificationGroup => ({
    id: `new_${Date.now()}`,
    name: null,
    userIds: [],
    alertIds: { grafana: [] },
    modificationDate: Date.now(),
    shiftDefinitionIds: [],
    ...notificationGroup,
});