import { ajaxRequest, getAction, getActionAjax } from "@comact/crc";
import _ from "lodash";
import { downloadFileFromAjaxRequest } from "../utils";
import { IArchive, IEventAlarm, IEventAlarmServer, IEventPunctual, IEventPunctualServer, IFile, IIdType, alarmEventTypes, pickAndPlaceEventTypes, punctualEventTypes } from "./model";
import { actionsCreators } from "./slices";

export const getEvents = getActionAjax(({ startTime, endTime, id, idType, nodeId }:
{ startTime?: number; endTime?: number; id?: string; idType?: IIdType; nodeId?: string; }) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => id && idType ? m.getPickAndPlaceInstanceEvents(id, idType, nodeId) : m.getEvents(startTime, endTime)),
        url: "event-manager/api/v2/events?" +
                (startTime ? `filter${encodeURIComponent("[date;gteq]")}=${startTime}` : "") +
                (endTime ? `&filter${encodeURIComponent("[date;lteq]")}=${endTime}` : "") +
                (id && idType ? `&filter${encodeURIComponent(`[${idType};eq]`)}=${id}` : "") +
                (nodeId ? `&filter${encodeURIComponent("[nodeId;like]")}=${nodeId}` : ""),
        onSuccess: (events: IEventPunctualServer[]) => {
            dispatch(actionsCreators.setPunctualEvents(convertEventsFromServer(events)));
        },
    })
));

export const clearAllEvents = getAction(() => async (dispatch) => {
    dispatch(actionsCreators.clearAllDurationEvents());
    dispatch(actionsCreators.clearAllPunctualEvents());
    return Promise.resolve();
});

export const getAlarms = getActionAjax((startTime: number, endTime: number) => (dispatch) => (
    startTime && endTime && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getAlarms(startTime, endTime)),
        url: `event-manager/api/v2/alarms/between?startTime=${startTime}&endTime=${endTime}`,
        onSuccess: (alarms: IEventAlarmServer[]) => {
            dispatch(actionsCreators.setDurationEvents(convertAlarmsFromServer(alarms)));
        },
    })
));

export const getEventArchiveList = getActionAjax(({ event, imageUuid }: { event?: IEventPunctual; imageUuid?: string; }) => () => (
    (event || imageUuid) && ajaxRequest({
        serverLessResolve: () => null, // FIXME: mock things
        url: ((() => {
            if (event) {
                return `/vault/api/v1/vault/documents/_search?type=${event.type}&${_.includes(pickAndPlaceEventTypes, event.type) ? `payloadId=${event.payloadId}` : `date=${event.dateStr}`}`;
            } else return `/vault/api/v1/vault/documents/_search?imageUuid=${imageUuid}`;
        }))(),
        method: "POST",
        data: {
            fields: ((() => {
                if (imageUuid) {
                    return [
                        {
                            operator: "EQUALS",
                            field: "index.imageUuid",
                            value: `${imageUuid}`,
                        },
                    ];
                } else if (_.includes(pickAndPlaceEventTypes, event.type)) {
                    return [
                        {
                            operator: "EQUALS",
                            field: "index.payloadId",
                            value: `${event.payloadId}`,
                        },
                    ];
                }
                return [
                    {
                        operator: "EQUALS",
                        field: "index.dateStr",
                        value: `${event.dateStr}`,
                    },
                    {
                        operator: "IN",
                        field: "tags",
                        value: [`${event.type}`],
                    },
                ];
            }))(),
        },
        onSuccess: (archiveList: IArchive[]) => archiveList,
    })
));

export const getArchiveFiles = getActionAjax((archiveId: string) => () => (
    !!archiveId && ajaxRequest({
        serverLessResolve: () => ["mocked", "archive", "list"], // FIXME: mock things
        url: `/vault/api/v1/vault/tar/${archiveId}/files`,
        onSuccess: (files: IFile[]) => files,
    })
));

export const downloadArchives = getAction((archiveIds: string[]) => async () => {
    const { promise, request } = ajaxRequest({
        url: "/vault/api/v1/vault/files/package?fileName=package.zip",
        method: "POST",
        rawResponse: true,
        responseType: "arraybuffer",
        data: archiveIds,
    });

    await promise;
    downloadFileFromAjaxRequest(request);
});

const convertEventsFromServer = (events: IEventPunctualServer[]) => (
    _.map(events, (e) => ({
        ...e,
        type: _.includes(punctualEventTypes, e.type) ? e.type : "punctual-other",
    } as IEventPunctual))
);

const convertAlarmsFromServer = (alarms: IEventAlarmServer[]) => (
    _.map(alarms, (a) => ({
        ...a,
        type: _.includes(alarmEventTypes, a.type) ? a.type : "duration-other",
    } as IEventAlarm))
);