import { ajaxRequest, getAction, getLocalizedText, getThrottleAjax } from "@comact/crc";
import { produce } from "immer";
import { requests as batchRequests } from "js/batches";
import { requests as databaseDefinitionsRequests } from "js/databaseDefinitions";
import { requests as contextDefinitionsRequests } from "js/kpis/contextDefinitions";
import _ from "lodash";
import slugify from "slugify";
import { API_PREFIX_PRODUCTION_MANAGER } from "../../constants";
import { NodesModule } from "../../node";
import { IKpiPattern } from "./model";
import { actionsCreators } from "./slices";

export const saveKpiPattern = getAction((kpiPattern: IKpiPattern, isNew: boolean) => (dispatch) => (
    ajaxRequest({
        method: isNew ? "POST" : "PUT",
        data: isNew ? { ...kpiPattern, uniqueName: `${slugify(getLocalizedText(kpiPattern.title, true))}-${Date.now()}` } : kpiPattern,
        serverLessResolve: () => ({ ...kpiPattern, id: isNew ? _.uniqueId() : kpiPattern.id, modificationDate: Date.now() }),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/kpi-patterns` + (isNew ? "" : `/${kpiPattern.id}`),
        showAjaxLoading: true,
        onSuccess: ((returnedKpiPattern: IKpiPattern) => {
            dispatch(actionsCreators.patch([returnedKpiPattern]));
        }),
    }).promise
));

export const deleteKpiPattern = (id: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const kpiPattern = getState().kpiPatterns[id];
    if (!kpiPattern) return Promise.reject();
    return ajaxRequest({
        method: "DELETE",
        serverLessResolve: () => kpiPattern,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/kpi-patterns/${kpiPattern.id}`,
        showAjaxLoading: true,
        onSuccess: ((returnedKpiPattern: IKpiPattern) => {
            dispatch(actionsCreators.delete([returnedKpiPattern.id]));
        }),
    }).promise;
};

export const duplicateKpiPattern = (id: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => {
    const kpiPattern = getState().kpiPatterns[id];
    if (!kpiPattern) return Promise.reject();

    const duplicated = produce(kpiPattern, (draft) => {
        draft.id = null;
        draft.modificationDate = Date.now();
        draft.uniqueName = draft.uniqueName + "_duplicated";
        draft.system = false;
        draft.title.values = _.mapValues(draft.title.values, (v) => v ? v + " *" : null);
    });
    return dispatch(saveKpiPattern(duplicated, true));
};

export const getKpiPatternsThrottled = getThrottleAjax((millId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().kpiPatterns ? _.values(getState().kpiPatterns) : import("./mocks").then((m) => m.getKpiPatterns()),
        url: process.env.EXEC_MODE == "icp"
            ? `${API_PREFIX_PRODUCTION_MANAGER}/kpi-patterns?nodeId=${millId}`
            : `${API_PREFIX_PRODUCTION_MANAGER}/kpi-patterns`,
        onSuccess: (kpiPatterns: IKpiPattern[]) => (
            Promise.all([
                NodesModule.getRequests(dispatch).fetchAllNodes().promise,
                dispatch(databaseDefinitionsRequests.getInfluxDatabaseDefinitionsThrottled()).promise,
                dispatch(batchRequests.getBatchDefinitions())?.promise,
                dispatch(contextDefinitionsRequests.getAllContextDefinitions(millId)).promise,
            ]).then(() => dispatch(actionsCreators.set({ kpiPatterns, millId })))
        ),
    })
), 10 * 1000);
