import { createModule } from "@comact/crc";
import * as requests from "./requests";
import * as selectors from "./selectors";
import reducers, { actionsCreators } from "./slices";

export * from "./links/model";
export * from "./model";

export const NodesModule = createModule({
    reducers, actionsCreators, requests, selectors,
});