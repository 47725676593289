import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IGrafanaAlert, IGrafanaAlerts } from "./model";

declare global {
    interface IStoreState {
        grafanaAlerts: IGrafanaAlerts;
    }
}

const useStats = "grafanaAlerts";

const grafanaAlerts = createSlice({
    name: "grafanaAlerts",
    initialState: null as IGrafanaAlerts,
    reducers: {
        set: (state, { payload }: PayloadAction<IGrafanaAlerts>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: payload,
                useStats,
            })
        ),
        patch: (state, { payload }: PayloadAction<IGrafanaAlert[]>) => (
            Utils.slices.patchWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats,
            })
        ),
    },
});

export const actionsCreators = {
    ...grafanaAlerts.actions,
};

export default {
    [grafanaAlerts.name]: grafanaAlerts.reducer,
};