import { ButtonColorTemplate, ModalModule, UserModule, app, t, useMapDispatch, useMapState } from "@comact/crc";
import _ from "lodash";
import React from "react";
import { usePageNodeContext } from "../../../../node/hooks";
import * as requests from "../../../requests";
import { getDashboardExtraById } from "../../../selectors";

export const useDashboardItemData = (id: string) => {
    const { nodeId } = usePageNodeContext();
    const user = useMapState((state) => UserModule.selectors.getCurrentUser(state));

    const { onDuplicate, onDelete, onMigrate, addFavorite, removeFavorite } = useMapDispatch((dispatch) => ({
        onDuplicate: () => ModalModule.confirm({
            header: t("widgetsLayout.modalDuplicate.title"),
            description: t("widgetsLayout.modalDuplicate.description"),
        }).then((accepted: boolean) => {
            if (accepted) dispatch(requests.duplicateDashboard(id));
        }),
        onDelete: () => ModalModule.confirm({
            header: t("widgetsLayout.modalDelete.title"),
            description: t("widgetsLayout.modalDelete.description"),
            buttonAcceptLabel: t("core:buttons.delete"),
            buttonColorTemplate: ButtonColorTemplate.RedAccept,
        }).then((accepted: boolean) => {
            if (accepted) dispatch(requests.deleteDashboard(id));
        }),
        onMigrate: async () => {
            const updatedDashboardsId = await dispatch(requests.convertOldDashboard(id));
            if (updatedDashboardsId) {
                app.goToUrl(`/dashboards/edit/${nodeId}/migrate-dashboards/${updatedDashboardsId.join(",")}`);
            }
        },
        addFavorite: () => dispatch(UserModule.requests.patchCurrentUser({ id: user.id, favoriteDashboards: _.concat(user.favoriteDashboards, id) })),
        removeFavorite: () => dispatch(UserModule.requests.patchCurrentUser({ id: user.id, favoriteDashboards: _.filter(user.favoriteDashboards, (favId) => favId != id) })),
    }), [nodeId, id, user]);

    const dashboardExtra = useMapState((state) => getDashboardExtraById(state, id), [id]);

    const canBeViewed = React.useMemo(() => dashboardExtra && !dashboardExtra.needsMigration && dashboardExtra.isValid, [dashboardExtra]);

    const isFavorite = React.useMemo(() => _.includes(user?.favoriteDashboards, id), [user?.favoriteDashboards, id]);

    const { author, lastModificationUser } = useMapState((state) => ({
        author: dashboardExtra && UserModule.selectors.getPartialUserById(state, dashboardExtra.userId),
        lastModificationUser: dashboardExtra && UserModule.selectors.getPartialUserById(state, dashboardExtra.lastModificationUserId),
    }), [dashboardExtra]);

    return { onDuplicate, onDelete, onMigrate, addFavorite, removeFavorite, canBeViewed, dashboardExtra, isFavorite, author, lastModificationUser, isLoggedIn: !!user };
};
