import _ from "lodash";
import { INode } from "../node";

export type IotFileType = "CONF" | "CONFIG_ACTIVATION" | "LOG" | "BACKUP_COMPONENT" | "BACKUP_SYSTEM";
export type IBackupsType = "system-backups" | "activation-history" | "application-logs" | "service-backups";

export interface IBackupAbstract {
    name: string;
    dateTime: number;
    type: "conf" | "active" | "logs" | "component" | "cmoc";
}

export type IBackup = IBackupSystem | IBackupService | IBackupLog;
export type IBackupServer = IBackupSystemServer | IBackupServiceServer | IBackupLogServer;
export type IBackups = IBackupsSystem | IBackupsService | IBackupLogs;

export const perspectiveToIotFileType: Record<Exclude<IBackupsType, "activation-history">, Exclude<IotFileType, "CONF" | "CONFIG_ACTIVATION" >> = {
    ["system-backups"]: "BACKUP_SYSTEM",
    ["service-backups"]: "BACKUP_COMPONENT",
    ["application-logs"]: "LOG",
};

// System and service backups
export interface IBackupSystemServer extends IBackupAbstract {
    type: "cmoc";
}

export interface IBackupSystem extends IBackupSystemServer {
    nodeId: string;
}

export type IBackupsSystem = { [name: string]: IBackupSystem; };

export interface IBackupServiceServer extends IBackupAbstract {
    type: "component";
}

export interface IBackupService extends IBackupServiceServer {
    nodeId: string;
}

export type IBackupsService = { [name: string]: IBackupService; };

export type ICmocBackup = IBackupSystem | IBackupService;
export type ICmocBackupServer = IBackupSystemServer | IBackupServiceServer;

// Application logs
export interface IBackupLogServer extends IBackupAbstract {
    type: "logs";
    missingCreationTime: boolean | null; // for icp only
}

export interface IBackupLog extends IBackupLogServer {
    nodeId: string;
    prevDateTime?: number | null;
    prevMissingCreationTime?: boolean | null;
}

export type IBackupLogs = { [name: string]: IBackupLog; };

// Application history
export interface IActivationHistoryServer {
    configId: string;
    deviceId: string;
    type: IActivationHistoryType;
    name: string;
    plcRef: number;
    activationTimestamp: string; // date ex.: 2021-06-16T13:19:04+00:00
    products?: IProduct[]; // only when loading complete config
    mechanic?: object; // only when loading complete config
    bundleDefinitions?: object; // only when loading complete config
}

export interface IActivationHistory extends IActivationHistoryServer {
    nodeId: string;
}

export interface IActivationHistoryGroup {
    id: string;
    name: string;
    plcRef: number;
    active: boolean;
    configCount: number;
    type: IActivationHistory["type"];
    startDate: string;
    endDate: string;
}

export interface IProduct {
    id: string;
    name: string;
    price: number;
    color: string;
    grade: {
        name: string;
        nominal: number;
    };
    thickness: {
        name: string;
        nominal: number;
    };
    width: {
        name: string;
        nominal: number;
    };
    length: {
        name: string;
        nominal: number;
    };
    species: {
        name: string;
    }[];
}

export type IActivationHistoryType = "live" | "simulation";

export type IActivationHistories = { [configId: string]: IActivationHistory; };

export const isActiveConfiguration = (id: string, type: string, node: INode) => {
    if (node.templateName != "machineCodec") return null; // We can only check if a configuration is active if the node is a machineCodec
    if (!node) return false;
    const { liveConfigs, simulationConfigs } = node;
    if (type == "live" && _.split(liveConfigs, ",").includes(id)) return true;
    if (type == "simulation" && _.split(simulationConfigs, ",").includes(id)) return true;
    return false;
};