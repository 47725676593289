import { constants, ILocalizedString } from "@comact/crc";
import { IEvent } from "@comact/crc/modules/schedule";
import _ from "lodash";
import moment from "moment";
const { ONE_HOUR } = constants;

const HALF_HOUR = ONE_HOUR / 2;

export interface IShift extends IEvent {
    readonly modificationStamp?: number;
    definitionId?: string; // id to find the IShiftDefinition
    workTeam: string;
    supervisor: string;
    events: (IEvent & { endTime: number; })[]; // breaks
    endOfShiftJobIds: string[];
    readonly finished?: boolean; // if the shift is finished, only for back end
}

export type IShifts = { [id: string]: IShift; };

export interface IShiftDefinition {
    readonly modificationStamp?: number;
    useOccurrences: boolean;
    canSwitchToggleOccurences: boolean;
    readonly id?: string;
    shiftTemplate: IShift;
    endType: "DATE" | "NB_OCCURRENCES";
    startDateRange: number;
    endDateRange?: number; // when endType == "DATE"
    nbOccurrence?: number; // when endType == "NB_OCCURRENCES"
    nbWeeks: number;
    endOfShiftJobIds: string[];
    // Selected days
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
}

export type IShiftDefinitions = { [id: string]: IShiftDefinition; };

export type IShiftsIds = {
    current: string;
    last: string;
};

export const shiftDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"] as const;

export type IActionType = "edit" | "edit-definition" | "new";

export const viewTypes = ["ScheduleList", "DaysList7", "DaysList3", "DaysList1", "MonthList"] as const;
export type IPageShiftViewType = typeof viewTypes[number];

export interface IEndOfShiftStatus {
    statusMessage: ILocalizedString;
    state: "CREATED" | "IN_PROGRESS" | "DONE" | "ERROR" | "WARNING";
    exceptions?: {
        message: string;
    };
    done: boolean;
}

export const createNewShiftDefinition = (partialShiftDefinition?: Partial<IShiftDefinition>): IShiftDefinition => ({
    useOccurrences: true,
    canSwitchToggleOccurences: true,
    nbOccurrence: 0,
    shiftTemplate: createNewShift(),
    endType: "DATE",
    startDateRange: moment().startOf("day").valueOf(),
    endDateRange: moment().startOf("day").add(1, "month").valueOf(), // same day of next month
    nbWeeks: 1,
    endOfShiftJobIds: [], // FIXME: voir plus tard si on peut l'enlever au backend aussi ?
    // Selected days
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: false,
    sunday: false,
    ...partialShiftDefinition,
});

export const createNewShift = (partialShift?: Partial<IShift>): IShift => ({
    name: "",
    description: "",
    startDate: moment().startOf("day").valueOf(),
    startTime: Math.round((new Date().getHours() * ONE_HOUR + HALF_HOUR) / HALF_HOUR) * HALF_HOUR, // default start time (approx. of current time)
    duration: ONE_HOUR,
    events: [],
    endOfShiftJobIds: [],
    workTeam: "",
    supervisor: "",
    definitionId: null,
    ...partialShift,
});

export const getShiftsByTimeRange = (shifts: IShift[], startDate: Date, endDate: Date): IShift[] => {
    const startTime = startDate.getTime();
    const endTime = endDate.getTime() - 1;
    return shifts.filter((shift) => (
        (shift.startDate + shift.startTime + shift.duration) >= startTime && (shift.startDate + shift.startTime) <= endTime
    ));
};

export const getEndTime = (shift: IShift) => shift.startDate + shift.startTime + shift.duration;

export const createEndOfShiftEvent = (shift: IShift): IEvent => {
    if (!shift) return null;
    const endOfShiftDateAndTime = new Date(shift.startDate + shift.startTime + shift.duration);
    const startDate = moment(endOfShiftDateAndTime).startOf("day").valueOf();
    const startTime = endOfShiftDateAndTime.getTime() - startDate;
    return {
        name: "end_of_shift",
        startDate,
        startTime,
        duration: 0,
    };
};

export const getNextShift = (initialMoment: number, candidates: IShift[]) => {
    const bestCandidate = _.reduce(candidates,
        (best: IShift, candidate: IShift): IShift => {
            const candidateMoment = candidate.startTime + candidate.startDate;
            const bestMoment = best.startTime + best.startDate;
            if (bestMoment < initialMoment) return candidate;
            if (candidateMoment < initialMoment) return best;
            return candidateMoment < bestMoment ? candidate : best;
        },
        candidates[0]);

    if (!bestCandidate || (bestCandidate.startTime + bestCandidate.startDate < initialMoment)) return null;
    return bestCandidate;
};

/** Format a duration into a string (HH:MM) */
export const formatDuration = (duration: number) => {
    if (duration == null) return "--:--";
    const minutes = Math.round(duration / (1000 * 60));
    return _.padStart((Math.floor(minutes / 60)).toString(), 2, "0") + ":" + _.padStart((minutes % 60).toString(), 2, "0");
};

/**
 * Shift definitions for Notification Groups shift assignment.
 */
export interface IShiftDefinitionNotifications {
    readonly id: string;
    readonly name: string;
    readonly locationName: string;
    readonly millName: string;
}

export type IShiftDefinitionsNotifications = Record<string, IShiftDefinitionNotifications>;