import { ILocalizedString } from "@comact/crc";
import _ from "lodash";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const templateNamesAndVersions = [ // Manageable templates names and versions (Other template exist but are not managed by the front)
    { name: "iep", versions: ["1.0.0"], types: ["iep-1.0.0"] },
    { name: "machineCodec", versions: ["1.0.0"], types: ["machineCodec-1.0.0"] },
    { name: "scannerCodec", versions: ["1.0.0"], types: ["scannerCodec-1.0.0"] },
    { name: "millCodec", versions: ["1.0.0"], types: ["millCodec-1.0.0"] },
    { name: "customer", versions: ["1.0.0"], types: ["customer-1.0.0"] },
    { name: "location", versions: ["1.0.0"], types: ["location-1.0.0"] },
    { name: "L5X", versions: ["1.0.0"], types: ["L5X-1.0.0"] },
    { name: "machineComponent", versions: ["1.0.0"], types: ["machineComponent-1.0.0"] },
    { name: "cameraServer", versions: ["1.0.0"], types: ["cameraServer-1.0.0"] },
    { name: "camera", versions: ["1.0.0"], types: ["camera-1.0.0"] },
    { name: "standaloneCamera", versions: ["1.0.0"], types: ["standaloneCamera-1.0.0"] },
    { name: "smartVisionZone", versions: ["1.0.0"], types: ["smartVisionZone-1.0.0"] },
    { name: "genericDevice", versions: ["1.0.0"], types: ["genericDevice-1.0.0"] },
    { name: "pickAndPlace", versions: ["1.0.0"], types: ["pickAndPlace-1.0.0"] },
] as const;

export type ITemplateType = typeof templateNamesAndVersions[number]["types"][number];

export const isTemplateKpiEnabled = (templateName: string): boolean => _.includes(["machineCodec", "scannerCodec", "smartVisionZone"] as ITemplate["name"][], templateName);
export interface ITemplateServer {
    id: string;
    name: typeof templateNamesAndVersions[number]["name"]; // The back will give use unknown template names but we won't manage them
    version: typeof templateNamesAndVersions[number]["versions"][number]; // The back will give use unknown template versions but we won't manage them
    type: "SYSTEM" | "CODEC" | "APPLICATION" | "DATA" | "USER";
    system: boolean;
    allowedParents: string[];
    properties?: { [name: string]: ITemplateProp; };
    creationDate?: number;
    modificationDate?: number;
    singleton?: unknown; // only used to match with the server
    configMap?: unknown; // only used to match with the server
}

export interface ITemplate extends ITemplateServer {
    properties: { [name: string]: ITemplateProp; } & ITemplateWellKnownProperties;
}

const convertTemplateFromServer = (templateServer: ITemplateServer): ITemplate => ({
    ...templateServer,
    properties: {
        ...templateWellKnownProperties,
        ...templateServer.properties,
    },
});

export const convertTemplatesFromServer = (templatesServer: ITemplateServer[]): ITemplates => _(templatesServer).map(convertTemplateFromServer).keyBy(({ id }) => id).value();

export const templateWellKnownProperties = {
    nodeState: { name: "nodeState", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp,
    templateVersion: { name: "templateVersion", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp,
    templateName: { name: "templateName", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp,
    parentId: { name: "parentId", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: false } as ITemplateTextProp,
    templateId: { name: "templateId", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: false } as ITemplateTextProp,
    name: { name: "name", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: false } as ITemplateTextProp,
    icpConnectivity: { name: "icpConnectivity", type: "BOOLEAN", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateBooleanProp,

    deleted: { name: "deleted", type: "BOOLEAN", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateBooleanProp, // ICP
    $version: { name: "$version", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp, // ICP
    $metadata: { name: "$metadata", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp, // ICP
    authorizedGroupIds: { name: "authorizedGroupIds", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp, // ICP
    mill: { name: "mill", type: "TEXT", order: 0, defaultValue: null, mandatory: false, readOnly: true } as ITemplateTextProp, // ICP
};

type ITemplateWellKnownProperties = typeof templateWellKnownProperties;

export interface ITemplateMachineCodec extends ITemplate {
    name: "machineCodec";
    properties: {
        machine: ITemplateChoiceProp;
        host: ITemplateTextProp;
        minGap: ITemplateDoubleProp;
        maxGap: ITemplateDoubleProp;
        recommendedGap: ITemplateDoubleProp;
        acquisitionMinDate: ITemplateDateTimeProp;
        downtimeDetectionTime: ITemplateDoubleProp;
    } & ITemplateWellKnownProperties;
}

export interface ITemplateScannerCodec extends ITemplate {
    name: "scannerCodec";
    properties: {
        scannerIndex: ITemplateChoiceProp;
    } & ITemplateWellKnownProperties;
}

interface ITemplatePropAbstract {
    name: string;
    order: number;
    type: "TEXT" | "BOOLEAN" | "CHOICE" | "LONG" | "DOUBLE" | "DATE_TIME" | "OBJECT";
    category?: ILocalizedString;
    description?: ILocalizedString;
    mandatory: boolean;
    readOnly?: boolean;
    defaultValue: unknown;
}
export interface ITemplateTextProp extends ITemplatePropAbstract {
    type: "TEXT";
    defaultValue: string;
    min?: number;
    max?: number;
}
export interface ITemplateBooleanProp extends ITemplatePropAbstract {
    type: "BOOLEAN";
    defaultValue: boolean;
}
export interface ITemplateChoiceProp extends ITemplatePropAbstract {
    type: "CHOICE";
    choices: string[];
    defaultValue: string;
}
export interface ITemplateLongProp extends ITemplatePropAbstract {
    type: "LONG";
    defaultValue: number;
    min?: number;
    max?: number;
}
export interface ITemplateDoubleProp extends ITemplatePropAbstract {
    type: "DOUBLE";
    defaultValue: number;
    min?: number;
    max?: number;
    precision?: number;
}
export interface ITemplateDateTimeProp extends ITemplatePropAbstract {
    type: "DATE_TIME";
    defaultValue: number;
}
export interface ITemplateObjectProp extends ITemplatePropAbstract {
    type: "OBJECT";
    defaultValue: {};
}
export type ITemplateProp = ITemplateTextProp | ITemplateBooleanProp | ITemplateChoiceProp | ITemplateLongProp | ITemplateDoubleProp | ITemplateDateTimeProp | ITemplateObjectProp;
export type ITemplates = { [id: string]: ITemplate; };

// Return all the propreties that a user can edit
export const getEditableProperties = (template: ITemplate, useWellKnownProperties: boolean = true) => {
    if (template?.system) return {};

    const templateProperties = _(template?.properties)
        .pickBy((_p, key) => useWellKnownProperties || !templateWellKnownProperties[key]) // remove wellknown properties of properties to edit
        .pickBy(({ readOnly, type }) => !readOnly && type != "OBJECT") // readonly properties or with object types are not editable from this page.
        .value();
    return templateProperties;
};
