import { t } from "@comact/crc";
import { IBatchDefinitions } from "js/batches";
import { IDatabaseDefinition, IDatabaseDefinitions } from "js/databaseDefinitions";
import { IContextDefinitions } from "js/kpis/contextDefinitions";
import { IKpiDefinition, IKpiDefinitionErrors, validateKpiDefinition } from "js/kpis/kpiDefinitions";
import { IKpiPattern } from "js/kpis/kpiPatterns";
import { CUSTOM_MUNIT } from "js/mUnits";
import { validateLocalizeString } from "js/utils";
import _ from "lodash";
import { IMachineCodecNode, IScannerCodecNode } from "../../node";
import { FormulaType } from "./formula/model";

export interface IKpiPatternEditor extends IKpiPattern { // same as editor ?
    availableDatabaseDefinitions: IDatabaseDefinitions | null;
    availableBatchDefinitions: IBatchDefinitions | null;
    contexts: IContextDefinitions;
    machines: Record<string, IMachineCodecNode>;
    scanners: Record<string, IScannerCodecNode>;
    unitShortText: string;
    errors: IKpiPatternEditorErrors;
    chosenDatabaseDefinition: IDatabaseDefinition;
    canSubmit: boolean;
    isEditing: boolean;
    isNew: boolean;
}

export const convertKpiPatternEditorToKpiPattern = (kpiPatternEditor: IKpiPatternEditor): IKpiPattern => {
    const { availableBatchDefinitions, availableDatabaseDefinitions, contexts, machines, scanners, ...kpiPattern } = kpiPatternEditor;
    if (kpiPatternEditor.database == "kpi") {
        kpiPattern.kpisInFormula = _.filter(kpiPatternEditor.kpisInFormula, ({ label }) => _.includes(kpiPatternEditor.formula, label));
    }
    if (kpiPattern.groupRatio || kpiPattern.filterRatio) { // when one of these are selected, force unit to %
        kpiPattern.unitType = "PERCENTAGE";
        kpiPattern.unitLabel = { values: {} };
    }
    return kpiPattern;
};

export interface IKpiPatternEditorErrors {
    title?: { [id: string]: string; };
    uniqueName?: string[];
    machinesModel?: string[];
    machinesScanner?: string[];
    tags?: string[];
    queryFormula?: string[];
    filterFormula?: string[];
    order?: string[];
    filterRatio?: string[];
    groups?: string[];
    groupRatio?: string[];
    unitType?: string[];
    unitLabel?: { [id: string]: string; };
    database?: string;
    kpiDefinitions?: { [id: string]: IKpiDefinitionErrors; };
}

/**
 * Validate a KpiPattern object and return and object of errors.
 */
export const validateKpiPatternEditor = (state: IStoreState): IKpiPatternEditorErrors => {
    const {
        kpiPatternEditor: { title, collection, groups, unitType, unitLabel, kpiDefinitions, groupRatio, filterRatio, database },
        kpiPatternEditorFormulaErrors,
    } = state;

    // FIXME: ce serait pas plutôt à mettre directement dans KpiPattern (au lieu que l'éditeur)
    let errors: IKpiPatternEditorErrors = {};

    // Title
    errors.title = validateLocalizeString(title);

    if (_.isEmpty(database)) errors.database = t("core:errors.notEmpty");

    // Add formula errors (errors from the components and from the server)
    errors.queryFormula = [...kpiPatternEditorFormulaErrors[FormulaType.QUERY].local, ...kpiPatternEditorFormulaErrors[FormulaType.QUERY].remote];
    errors.filterFormula = [...kpiPatternEditorFormulaErrors[FormulaType.FILTER].local, ...kpiPatternEditorFormulaErrors[FormulaType.FILTER].remote];

    // Group (for collection)
    if (collection && (groups.some((group) => group == null) || groups.length == 0)) {
        errors.groups = groups.length == 0
            ? [t("core:errors.notEmpty")] // empty array
            : groups.map((group) => group == null ? t("core:errors.notEmpty") : null); // array with errors
    }

    // Can't select both
    if (groupRatio && filterRatio) {
        errors.groupRatio = [t("kpi.pattern.errors.bothPercent")];
        errors.filterRatio = [t("kpi.pattern.errors.bothPercent")];
    }

    // Unit
    if (!groupRatio && !filterRatio) {
        if (unitType == CUSTOM_MUNIT) {
            errors.unitLabel = validateLocalizeString(unitLabel);
        } else {
            errors.unitType = _.isEmpty(unitType) ? [t("core:errors.notEmpty")] : null;
        }
    }

    // KpiDefinitions
    errors.kpiDefinitions = _.reduce(kpiDefinitions, (obj, kDef: IKpiDefinition) => {
        const kpiDefErrors = validateKpiDefinition(kDef);
        if (!_.isEmpty(kpiDefErrors)) obj[kDef.id] = kpiDefErrors;
        return obj;
    }, {});

    errors = _.omitBy(errors, _.isEmpty); // Remove empty key
    return _.isEmpty(errors) ? null : errors; // Return object of not empty
};
