import { CSS } from "@comact/crc/modules/kit";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import tinycolor from "tinycolor2";
import { NodesModule } from "../../node";
import { getKpiPatterns } from "../kpiPatterns/selectors";
import { IKpiAlert, IKpiAlerts } from "./model";

export const getKpiAlertById = ({ kpiAlerts }: { kpiAlerts: IKpiAlerts; }, id: string): IKpiAlert => kpiAlerts?.[id];

export const getNodeListOptions = memoize(({ state, nodeId }: { state: IStoreState; nodeId: string; }) => {
    const nodesAllowedByKpis = _.chain(getKpiPatterns({ state }))
        .reduce((prev, k) => [...prev, ...k.nodeIds], [] as string[])
        .uniq()
        .value();

    const childNodes = NodesModule.selectors.getNodesChildrenOfTypeRecursive(state, nodeId, null, true);
    return (
        _.chain(childNodes)
            .reduce((arr, node) => {
                if (nodesAllowedByKpis.includes(node.id)) { // we only show nodes that are availables in all the kpis of the system
                    arr.push({
                        label: `${NodesModule.selectors.getNodeTemplateNameLocalized(state, node.id)}: ${NodesModule.selectors.getNodeNamePath(state, node.id, true)}`,
                        value: node.id,
                    });
                }
                return arr;
            }, [] as { label: string; value: string; }[])
            .orderBy(({ label }) => label.toLocaleLowerCase())
            .value()
    );
});

export const getNodeName = memoize(({ state, nodeId }: { state: IStoreState; nodeId: string; }) => NodesModule.selectors.getNodeNamePath(state, nodeId));

export const getNodeTemplateName = memoize(({ state, nodeId }: { state: IStoreState; nodeId: string; }) => NodesModule.selectors.getNodeTemplateNameLocalized(state, nodeId));

export const getAllNodeNamesOrganizedById = memoize((state: IStoreState): { [nodeId: string]: string; } => (
    _.reduce(NodesModule.selectors.getAllNodes(state), (prev, { id }) => ({ ...prev, [id]: NodesModule.selectors.getNodeNamePath(state, id) }), {})));

export const getColorForValue = (value: string) => {
    switch (value) {
        case "empty":
            return `${CSS.colors.grey} !important`;
        case "warn":
            return `${tinycolor(CSS.colors.yellow).spin(-3).darken(3).toRgbString()} !important`;
        case "normal":
            return `${CSS.colors.green} !important`;
        case "error":
            return `${CSS.colors.red} !important`;
        default:
            return "inherit";
    }
};