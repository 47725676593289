import { UserModule, ajaxRequest, getActionAjax } from "@comact/crc";
import { IAccessToken, IAccessTokens, IAccessURLs } from "./model";
import { actionsCreators } from "./slices";

export const getAccessURLs = getActionAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getAccessURLs()),
        url: "/api/v1/raw-data-urls",
        onSuccess: (accessURLs: IAccessURLs) => {
            dispatch(actionsCreators.setAccessURLs(accessURLs));
        },
    })
));

export const getAccessTokens = getActionAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().accessTokens || import("./mocks").then((m) => m.getAccessTokens()),
        url: "/api/v1/apikeys",
        onSuccess: (accessTokens: IAccessTokens) => {
            dispatch(actionsCreators.setAccessToken(accessTokens));
        },
    })
));

export const generateAccessToken = getActionAjax((data: { label: string; }) => (dispatch, getState: () => IStoreState) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.generateAccessToken(UserModule.selectors.getCurrentUserId(getState()), data.label)),
        url: "/api/v1/apikeys",
        method: "POST",
        data,
        onSuccess: (accessToken: IAccessToken) => {
            dispatch(actionsCreators.patchAccessToken([accessToken]));
            return accessToken;
        },
    })
));

export const deleteAccessToken = getActionAjax((id: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => null,
        url: `/api/v1/apikeys/${id}`,
        method: "DELETE",
        onSuccess: () => {
            dispatch(actionsCreators.deleteAccessToken([id]));
        },
    })
));
