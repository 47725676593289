import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { INotificationGroup, INotificationGroups } from "./model";

declare global {
    interface IStoreState {
        notificationGroups: INotificationGroups;
    }
}

const useStats = "notificationGroups";

const isEqual = (a: INotificationGroup, b: INotificationGroup) => (
    a.modificationDate == b.modificationDate && _.size(a.alertIds.grafana) == _.size(b.alertIds.grafana) && _.size(a.userIds) == _.size(b.userIds)
);

const notificationGroups = createSlice({
    name: "notificationGroups",
    initialState: null as INotificationGroups,
    reducers: {
        set: (state, { payload }: PayloadAction<INotificationGroups>) => (
            Utils.slices.set({
                state,
                nextState: payload,
                isEqual,
                useStats,
            })
        ),
        patch: (state, { payload }: PayloadAction<INotificationGroup[]>) => (
            Utils.slices.patch({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                isEqual,
                useStats,
            })
        ),
        delete: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats })
        ),
    },
});

export const actionsCreators = {
    ...notificationGroups.actions,
};

export default {
    [notificationGroups.name]: notificationGroups.reducer,
};