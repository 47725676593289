import { ajaxRequest, getAction, t } from "@comact/crc";
import _ from "lodash";
import moment from "moment";
import { API_PREFIX_PRODUCTION_MANAGER } from "../constants";
import { IBatch, IBatchConfig, IBatchDefinition, IBatchDefinitions } from "./model";
import { actionsCreators } from "./slices";

export const getBatchConfig = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    process.env.EXEC_MODE == "cmoc" && getState().system?.isMaster && ajaxRequest({
        serverLessResolve: () => getState().batchConfig ? _.values(getState().batchConfig) : import("./mocks").then((m) => m.getBatchConfig()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/batch-config`,
        onSuccess: (batchConfig: IBatchConfig) => {
            if (_.isObjectLike(batchConfig)) dispatch(actionsCreators.setBatchConfig(batchConfig));
            else throw new Error(t("batches.errors.invalidResponse"));
        },
    })
);

export const getBatchDefinitions = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    getState().batchConfig?.batchProcessingEnabled && ajaxRequest({
        serverLessResolve: () => getState().batchDefinitions ? _.values(getState().batchDefinitions) : import("./mocks").then((m) => m.getBatchDefinitions()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/batch-definitions`,
        onSuccess: (batchDefinitions: IBatchDefinition[]) => {
            if (_.isObjectLike(batchDefinitions)) dispatch(actionsCreators.setBatchDefinitions(_.keyBy(batchDefinitions, ({ id }) => id)));
            else throw new Error(t("batches.errors.invalidResponse"));
        },
    })
);

export const saveBatchConfig = (config: IBatchConfig, initialConfig: IBatchConfig) => (dispatch: IStoreDispatch) => (
    !_.isEqual(config, initialConfig) && ajaxRequest({
        method: "PUT",
        data: config,
        showAjaxLoading: true,
        serverLessResolve: () => config,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/batch-config`,
        onSuccess: ((batchConfig: IBatchConfig) => {
            if (_.isObjectLike(batchConfig)) dispatch(actionsCreators.setBatchConfig(batchConfig));
            else throw new Error(t("batches.errors.invalidResponse"));
        }),
    }).promise
);

export const saveBatchDefinitions = getAction((batchDefinitions: IBatchDefinition[], initialBatchDefinitions: IBatchDefinitions) => (
    async (dispatch: IStoreDispatch) => {
        const ajaxPromises: Promise<void>[] = [];

        const deleted = _.differenceBy(_.values(initialBatchDefinitions), batchDefinitions, (def) => def.id);
        if (!_.isEmpty(deleted)) ajaxPromises.push(dispatch(deleteBatchDefinitions(deleted)));

        const created = _.filter(batchDefinitions, (def) => def.id.startsWith("new_"));
        if (!_.isEmpty(created)) _.forEach(created, (def) => { ajaxPromises.push(dispatch(createBatchDefinition(def))); });

        const updated = _.filter(batchDefinitions, (def) => !def.id.startsWith("new_") && !_.isEqual(def, initialBatchDefinitions[def.id]));
        if (!_.isEmpty(updated)) ajaxPromises.push(dispatch(updateBatchDefinitions(updated)));

        // wait until all the requests are completed
        await Promise.all(ajaxPromises);
        // refresh all data
        await dispatch(getBatchDefinitions()).promise;
    }));

export const createBatchDefinition = (definition: IBatchDefinition) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: "POST",
        showAjaxLoading: true,
        data: { ...definition, id: null },
        serverLessResolve: () => Promise.resolve({ ...definition, id: _.uniqueId(), timestamp: Date.now() }), // replace new ids with real ids
        url: `${API_PREFIX_PRODUCTION_MANAGER}/batch-definitions?id=${definition.id}`,
        onSuccess: ((batchDefinition: IBatchDefinition) => {
            if (_.isObjectLike(batchDefinition)) dispatch(actionsCreators.patchBatchDefinitions([batchDefinition]));
            else throw new Error(t("batches.errors.invalidResponse"));
        }),
    }).promise
);

export const updateBatchDefinitions = (definitions: IBatchDefinition[]) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: "PUT",
        data: definitions,
        showAjaxLoading: true,
        serverLessResolve: () => _.map(definitions, (def) => ({ ...def, timestamp: Date.now() })),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/batch-definitions?id=${encodeURIComponent(_.map(definitions, (batchDef) => batchDef.id).join(","))}`,
        onSuccess: ((batchDefinitions: IBatchDefinition[]) => {
            if (_.isObjectLike(batchDefinitions)) dispatch(actionsCreators.patchBatchDefinitions(batchDefinitions));
            else throw new Error(t("batches.errors.invalidResponse"));
        }),
    }).promise
);

export const deleteBatchDefinitions = (definitions: IBatchDefinition[]) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    getState().batchConfig?.batchProcessingEnabled && ajaxRequest({
        method: "DELETE",
        showAjaxLoading: true,
        serverLessResolve: () => null,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/batch-definitions?id=${encodeURIComponent(_.map(definitions, ({ id }) => id).join(","))}`,
        onSuccess: (() => {
            dispatch(actionsCreators.deleteBatchDefinitions(_.map(definitions, ({ id }) => id)));
        }),
    }).promise
);

/**
 * Get all batches between the begin time and end time
 */
export const getBatchesByTimeRange = (begin: number, end: number) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    getState().batchConfig?.batchProcessingEnabled && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getBatchesByTimeRange(begin, end)),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/batches/?begin=${begin}&end=${end}`,
        onSuccess: ((batches: IBatch[]) => {
            dispatch(actionsCreators.setByTimeRange({
                batches: _.keyBy(batches, ({ id }) => id),
                begin,
                end,
            }));
        }),
    })
);

export const getBatchesByCurrentMonth = () => (dispatch: IStoreDispatch) => dispatch(getBatchesByTimeRange(Date.now(), moment().startOf("month").valueOf()));

/**
 * Get the batch by id
 */
export const getBatchById = (id: string) => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    getState().batchConfig?.batchProcessingEnabled && ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getById(id)),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/batches/${id}`,
        onSuccess: ((batch: IBatch) => {
            dispatch(actionsCreators.patch([batch]));
        }),
    }).promise
);

/**
 * Get the current batch
 */
export const getBatchCurrent = () => (dispatch: IStoreDispatch, getState: () => IStoreState) => (
    getState().batchConfig?.batchProcessingEnabled &&
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getLast()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/schedule/batches/current`,
        onSuccess: (batch: IBatch) => {
            dispatch(actionsCreators.patch([batch]));
        },
    })
);
