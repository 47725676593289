import _ from "lodash";
import { create as createSeeder } from "random-seed";
import { ILabelTypes, LABELS_TYPE } from "../labels/model";
import { ILabelsGroupServer } from "./model";

const rand = createSeeder();

export const getLabelGroupsByType = (type: ILabelTypes) => {
    rand.seed(`getLabelsGroups_${type}`);
    const prev: ILabelsGroupServer[] = [];
    _.times(rand.intBetween(5, 10), (index) => {
        prev.push(getGroup(index, type));
    });
    return prev;
};

const getGroup = (index: number, type: ILabelTypes): ILabelsGroupServer => ({
    id: (index + 1).toString(), // We absolutely need a number because the server use a number for this id in the "label" interface.
    name: {
        values: {
            fr: `${type} Groupe #${index + 1}`,
            en: `${type} Group #${index + 1}`,
        },
    },
    sortingPriority: 1,
    type: LABELS_TYPE[type],
});