// API endpoints
export const API_PREFIX_PRODUCTION_MANAGER = process.env.EXEC_MODE == "icp" ? "/api/v1" : "/production-manager/api/v1";
export const API_TWIN_V1 = process.env.EXEC_MODE == "icp" ? "/api/v1" : "/twin/api/v1";
export const API_TWIN_V2 = process.env.EXEC_MODE == "icp" ? "/api/v2" : "/twin/api/v2";
export const API_PREFIX_MODELS = process.env.EXEC_MODE == "icp" ? "/api/v1/models" : "model-manager/api/v1/models";
export const API_PREFIX_DETECTIONS = process.env.EXEC_MODE == "icp" ? "/api/v1/detections" : "model-manager/api/v1/detections";
export const API_PREFIX_FILES_DASHBOARDS = process.env.EXEC_MODE == "icp" ? "/api/v1/dashboards" : `${API_PREFIX_PRODUCTION_MANAGER}/files/dashboards`;
export const API_PREFIX_USER_MANAGER = "/user-manager/api";
export const API_PREFIX_VAULT = "/vault/api/v1/vault";
export const API_PREFIX_ACCESS_TOKENS = "/api/v1/apikeys";
export const API_PREFIX_GRAFANA = "/api/v1/grafana";

// BACKUPS
export const API_PREFIX_CMOC_BACKUP_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/cmoc-backups`;
export const API_PREFIX_CMOC_BACKUP_URL = `${API_PREFIX_PRODUCTION_MANAGER}/cmoc-backups-url`;
export const API_PREFIX_LOG_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/cmoc-log-backups`;

export const API_PREFIX_OPTIMIZER_CONFIGS_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/optimizer-configurations`;

export const API_PREFIX_INTERVALS = `${API_PREFIX_PRODUCTION_MANAGER}/intervals`;

export const API_PREFIX_CONTEXT_DEFINITIONS = `${API_PREFIX_PRODUCTION_MANAGER}/contexts`;

export const API_PREFIX_CLOUD_FILES = `${API_PREFIX_PRODUCTION_MANAGER}/l5xfiles`;

export const API_PREFIX_DOWNTIMES = process.env.EXEC_MODE == "icp" ? `${API_PREFIX_PRODUCTION_MANAGER}/downtimes` : "/production-manager/api/v2/downtimes";

export const API_PREFIX_HLS = process.env.EXEC_MODE == "icp" ? "/api/v1/streams/camera/" : "/hls/";