import _ from "lodash";
import { createSelector } from "reselect";

export interface IDatabaseDefinition {
    id: string;
    collection: boolean;
    fields: {
        name: string;
        type: string;
    }[];
    tags: string[];
    modificationStamp: number;
}
export type IDatabaseDefinitions = { [id: string]: IDatabaseDefinition; };

/**
 * Generate fields
 */
export const generateFields = createSelector(
    (databaseDefinition: IDatabaseDefinition) => databaseDefinition,
    (databaseDefinition: IDatabaseDefinition) => databaseDefinition
        ? _.chain(databaseDefinition.fields).map((field) => "$" + databaseDefinition.id + "." + field.name).orderBy((field) => field.toLocaleLowerCase()).value()
        : []
);

/**
 * Generate tags
 */
export const generateTags = createSelector(
    (databaseDefinition: IDatabaseDefinition) => databaseDefinition,
    (databaseDefinition: IDatabaseDefinition) => databaseDefinition
        ? _.chain(databaseDefinition.tags).map((tag) => "$" + databaseDefinition.id + "." + tag).orderBy((tag) => tag.toLocaleLowerCase()).value()
        : []
);