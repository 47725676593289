/* eslint-disable max-len */
import { ajaxRequest, getAction, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_DETECTIONS, API_PREFIX_MODELS } from "../constants";
import { IDataflow, IDataflowServer, IDataflowsServer, IModel, convertDataflowToServer, convertDataflowsToServer } from "./model";
import * as selectors from "./selectors";
import { actions } from "./slices";

export const fetchAllModels = getThrottleAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getModels()),
        url: API_PREFIX_MODELS,
        onSuccess: (models: IModel[]) => {
            dispatch(actions.setModels(_.keyBy(models, ({ id }) => id)));
        },
    })
), 10 * 1000);

export const uploadModelFile = getAction((data: FormData) => async (dispatch) => (
    await ajaxRequest({
        // eslint-disable-next-line
        serverLessResolve: () => console.log("uploadModelFile:", (Object as any).fromEntries(data)),
        method: "POST",
        url: API_PREFIX_MODELS,
        contentType: "", // need to let the browser detect the file type itself so that it detect the multipart file boudaries
        showAjaxLoading: true,
        data,
        timeout: 10 * 60 * 1000, // longer timeout when uploading
        onSuccess: (model: IModel) => {
            dispatch(actions.patchModels([model]));
        },
    }).promise
));

export const editModelFile = getAction((data: IModel) => async (dispatch) => (
    await ajaxRequest({
        serverLessResolve: () => data,
        method: "PUT",
        url: `${API_PREFIX_MODELS}/${data.id}`,
        showAjaxLoading: true,
        data,
        onSuccess: (model: IModel) => {
            dispatch(actions.patchModels([model]));
        },
    }).promise
));

export const deletedModel = getAction((id: string) => async (dispatch) => (
    await ajaxRequest({
        method: "DELETE",
        url: `${API_PREFIX_MODELS}/${id}`,
        showAjaxLoading: true,
        onSuccess: () => {
            dispatch(actions.deleteModels([id]));
        },
    }).promise
));

export const cloudSyncModel = getAction((id: string) => (dispatch) => (
    ajaxRequest({
        method: "POST",
        url: `${API_PREFIX_MODELS}/sync/${id}`,
        onSuccess: (model: IModel) => {
            dispatch(actions.patchModels([model]));
        },
    }).promise
));

export const getDataflowLive = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().dataflowLive
            ? convertDataflowsToServer(getState().dataflowLive)
            : import("./mocks").then((m) => m.getDataflowLive()),
        url: `${API_PREFIX_DETECTIONS}/live`,
        onSuccess: (dataflowsServer: IDataflowsServer) => {
            dispatch(actions.setDataflowsLive(dataflowsServer));
        },
    })
), 10 * 1000);

export const getDataflowSimulation = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().dataflowSimulation
            ? convertDataflowsToServer(getState().dataflowSimulation)
            : import("./mocks").then((m) => m.getDataflowSimulation()),
        url: `${API_PREFIX_DETECTIONS}/simulation`,
        onSuccess: (dataflowsServer: IDataflowsServer) => {
            dispatch(actions.setDataflowsSimulation(dataflowsServer));
        },
    })
), 10 * 1000);

export const setDataflowState = getThrottleAjax((uid: number, state: IDataflow["state"], type: "live" | "simulation") => (dispatch, getState) => (
    ajaxRequest({
        method: "PUT",
        serverLessResolve: () => convertDataflowToServer({
            ...selectors.getDataflowById(getState(), uid, type),
            state,
        }),
        url: `${API_PREFIX_DETECTIONS}/${type}/state?detectionId=${uid}&state=${state}`,
        onSuccess: (dataflowServer: IDataflowServer) => {
            if (type == "live") {
                dispatch(actions.patchDataflowsLive([dataflowServer]));
            } else {
                dispatch(actions.patchDataflowsSimulation([dataflowServer]));
            }
        },
        showAjaxLoading: true,
        serverLessTimeout: 1000,
    })
), 10 * 1000);

export const setDataflowModel = getThrottleAjax((dataflowUid: number, modelIndex: number, id: string, type: "live" | "simulation") => (dispatch, getState) => (
    ajaxRequest({
        method: "PUT",
        serverLessResolve: () => {
            const dataflow = selectors.getDataflowById(getState(), dataflowUid, type);
            return convertDataflowToServer({
                ...dataflow,
                models: {
                    ...dataflow.models,
                    [modelIndex]: getState().models[id],
                },
            });
        },
        url: `${API_PREFIX_DETECTIONS}/${type}/activate?modelUUID=${id}&detectionIndex=${dataflowUid}&modelIndex=${modelIndex}`,
        onSuccess: (dataflowServer: IDataflowServer) => {
            if (type == "live") {
                dispatch(actions.patchDataflowsLive([dataflowServer]));
            } else {
                dispatch(actions.patchDataflowsSimulation([dataflowServer]));
            }
        },
        showAjaxLoading: true,
        serverLessTimeout: 1000,
        timeout: 60 * 1000,
    })
), 10 * 1000);