import { ajaxRequest, getAction, getThrottleAjax, t } from "@comact/crc";
import { API_PREFIX_PRODUCTION_MANAGER } from "js/constants";
import _ from "lodash";
import { IContextDefinition, IContextDefinitions } from "./model";
import { actionsCreators } from "./slices";

/**
 * Update all needed data for kpis patterns, in the right order
 */
export const getAllContextDefinitions = getThrottleAjax((millId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().contextDefinitions ? _.values(getState().contextDefinitions) : import("./mocks").then((m) => m.getContextDefinitions()),
        url: process.env.EXEC_MODE == "icp"
            ? `${API_PREFIX_PRODUCTION_MANAGER}/contexts?nodeId=${millId}`
            : `${API_PREFIX_PRODUCTION_MANAGER}/contexts?limit=9999`,
        error: { description: t("core:errors.errorConnectionAPI", { name: "main-wui (getAllContextDefinitions)" }) },
        onSuccess: (contextDefinitions: IContextDefinition[]) => {
            dispatch(actionsCreators.set(_.map(contextDefinitions, (k) => ({ ...k, millNodeId: millId }))));
        },
    })
), 10 * 1000);

export const saveContextDefinitions = getAction((contextDefinitions: IContextDefinitions, initialDefinitions: IContextDefinitions, millId: string) => (
    async (dispatch: IStoreDispatch) => {
        const ajaxPromises: Promise<void>[] = [];

        const deleted = _.differenceBy(_.values(initialDefinitions), _.values(contextDefinitions), ({ id }) => id);
        if (!_.isEmpty(deleted)) ajaxPromises.push(dispatch(deleteContextDefinitions(deleted)));

        const created: IContextDefinition[] = _.filter(contextDefinitions, ({ id }) => id.startsWith("new_"));
        if (!_.isEmpty(created)) ajaxPromises.push(dispatch(createContextDefinitions(created)));

        const updated: IContextDefinition[] = _.filter(contextDefinitions, (def) => !def.id.startsWith("new_") && !_.isEqual(def, initialDefinitions[def.id]));
        if (!_.isEmpty(updated)) ajaxPromises.push(dispatch(updateContextDefinitions(updated)));

        // wait until all the requests are completed
        await Promise.all(ajaxPromises);
        // refresh all data
        await dispatch(getAllContextDefinitions(millId)).promise;
    }));

export const createContextDefinitions = getAction((contextDefinitions: IContextDefinition[]) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: "POST",
        showAjaxLoading: true,
        data: contextDefinitions,
        serverLessResolve: () => _.map(contextDefinitions, (def) => ({ ...def, id: _.uniqueId(), modificationStamp: Date.now() })), // replace new_ ids with real ids
        url: `${API_PREFIX_PRODUCTION_MANAGER}/contexts?batch`,
        error: { description: t("core:errors.errorConnectionAPI", { name: "main-wui (createContextDefinitions)" }) },
        onSuccess: ((contextDefinitionsServer: IContextDefinition[]) => {
            dispatch(actionsCreators.patch(contextDefinitionsServer));
        }),
    }).promise
));

export const updateContextDefinitions = (contextDefinitions: IContextDefinition[]) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: "PUT",
        showAjaxLoading: true,
        data: contextDefinitions,
        serverLessResolve: () => _.map(contextDefinitions, (def) => ({ ...def, modificationStamp: Date.now() })),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/contexts?id=${encodeURIComponent(_.map(contextDefinitions, ({ id }) => id).join(","))}`,
        onSuccess: ((contextDefinitionsServer: IContextDefinition[]) => {
            dispatch(actionsCreators.patch(contextDefinitionsServer));
        }),
    }).promise
);

export const deleteContextDefinitions = (contextDefinitions: IContextDefinition[]) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        method: "DELETE",
        showAjaxLoading: true,
        serverLessResolve: () => null,
        url: `${API_PREFIX_PRODUCTION_MANAGER}/contexts?id=${encodeURIComponent(_.map(contextDefinitions, ({ id }) => id).join(","))}`,
        onSuccess: (() => {
            dispatch(actionsCreators.delete(_.map(contextDefinitions, ({ id }) => id)));
        }),
    }).promise
);
