import { getTimeMilli } from "@comact/crc";
import _ from "lodash";
import { memoize } from "proxy-memoize";
import { IActivationHistory, IActivationHistoryGroup, IBackup, IBackups, IBackupsType, isActiveConfiguration } from "./model";

const getBackupsByType = (<T extends IBackups>({ state, type }: { state: IStoreState; type: Exclude<IBackupsType, "activation-history">; }) => {
    switch (type) {
        case "application-logs": return state.logFiles as T;
        case "system-backups": return state.systemBackups as T;
        case "service-backups": return state.serviceBackups as T;
    }
});

export const getBackupsByTypeOrdered = memoize(<T extends IBackup>({ state, type }: { state: IStoreState; type: Exclude<IBackupsType, "activation-history">; }) => {
    const backups = getBackupsByType({ state, type });
    return backups ? _.orderBy(backups, ({ dateTime }) => dateTime, ["desc"]) as T[] : null;
});

export const getBackupsByTypeAndNodeId = memoize(<T extends IBackup>({ state, nodeId, type }: { state: IStoreState; nodeId: string; type: Exclude<IBackupsType, "activation-history">; }) => (
    getBackupsByType({ state, type }) ? _.filter(getBackupsByType({ state, type }), (b) => b.nodeId == nodeId) as T[] : null
));

// FIXME: this selector was taking a lot of time when we had more than 2000 elements, and Maxime will investigate this
export const getBackupByNameAndType = (<T extends IBackup>({ state, name, type }: { state: IStoreState; name: string; type: Exclude<IBackupsType, "activation-history">; }) => (
    _.find(getBackupsByType({ state, type }), (b) => b.name == name) as T
));

// Activation history
export const getActivationHistoryByConfigIdDesc = memoize(
    ({ activationHistories }: IStoreState) => _.orderBy(activationHistories, ({ configId }) => configId, ["desc"])
);

export const hasMultiplePlcRef = ({ activationHistories }: IStoreState) => _.some(activationHistories, ({ plcRef }) => plcRef != 0);

export const getActivationHistoryByNameTypeAndPlcRef = memoize(({ state: { activationHistories }, name, type, plcRef }: {
    state: IStoreState;
    name: IActivationHistory["name"];
    type: IActivationHistory["type"];
    plcRef: IActivationHistory["plcRef"];
}) => (
    _.chain(activationHistories)
        .filter((c) => c.name == name && c.plcRef == plcRef && c.type == type)
        .orderBy([
            ({ configId }) => configId,
            ({ activationTimestamp }) => [(new Date(activationTimestamp)).getTime],
        ], ["desc", "desc"])
        .value()
));

export const areActivationHistoriesLoaded = ({ activationHistories }: IStoreState) => !!activationHistories;

export const getActivationHistoryById = ({ activationHistories }: IStoreState, configId: string) => activationHistories[configId];

export const getActivationHistoryGroupByIdAndNodeId = memoize(({ state, id, nodeId }: { state: IStoreState; id: string; nodeId: string; }) => (
    getActivationHistoryGroupsByNodeId({ state, nodeId })[id]
));

export const getActivationHistoryGroupsByNodeId = memoize(({ state: { nodes, activationHistories }, nodeId }: { state: IStoreState; nodeId: string; }) => (
    _.chain(activationHistories)
        .filter((a) => a.nodeId == nodeId)
        .reduce((group, config) => {
            const id = `${_.startCase(config.type)} - ${config.name} - ${config.plcRef}`;
            if (!group[id]) group[id] = { id, name: config.name, plcRef: config.plcRef, active: false, type: config.type, configCount: 0, startDate: null, endDate: null };
            if (!group[id].active && isActiveConfiguration(config.configId, config.type, nodes[nodeId])) {
                group[id].active = true;
            }
            group[id].configCount++;
            const activationTimestamp = getTimeMilli(config.activationTimestamp);
            if (group[id].startDate == null || activationTimestamp < getTimeMilli(group[id].startDate)) {
                group[id].startDate = config.activationTimestamp;
            }
            if (activationTimestamp > getTimeMilli(group[id].endDate)) {
                group[id].endDate = config.activationTimestamp;
            }

            return group;
        }, {} as Record<string, IActivationHistoryGroup>)
        .value()
));

// FIXME: Will be put back in ICP-1369
// export const makeGetPreviousConfig = () => memoize(({ state: { activationHistories }, configId }: { state: IStoreState; configId: string; }) => {
//     const config = activationHistories[configId];

//     const ordered = _(activationHistories)
//         .filter((c) => c.type == config.type && c.name == config.name && c.plcRef == config.plcRef)
//         .orderBy((c) => c.configId, ["desc"])
//         .value();
//     const index = _.findIndex(ordered, (c) => c.configId == configId);
//     return ordered[index + 1];
// });