import { ajaxRequest, getActionAjax } from "@comact/crc";
import { API_PREFIX_GRAFANA } from "../../constants";
import { IGrafanaAlert, IGrafanaAlerts } from "./model";
import { actionsCreators } from "./slices";

export const getGrafanaAlerts = getActionAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getGrafanaAlerts()),
        url: `${API_PREFIX_GRAFANA}/alerts`,
        onSuccess: (grafanaAlerts: IGrafanaAlerts) => {
            dispatch(actionsCreators.set(grafanaAlerts));
        },
    })
));

export const getGrafanaAlertById = getActionAjax((id: string) => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getGrafanaAlertById(id)),
        url: `${API_PREFIX_GRAFANA}/alerts/${id}`,
        onSuccess: (grafanaAlert: IGrafanaAlert) => {
            dispatch(actionsCreators.patch([grafanaAlert]));
        },
    })
));