import { ajaxRequest, getThrottleAjax } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../constants";
import * as mocks from "./mocks";
import { IDatabaseDefinition } from "./model";
import { actionsCreators } from "./slices";

export const getInfluxDatabaseDefinitionsThrottled = getThrottleAjax(() => (dispatch) => (
    ajaxRequest({
        serverLessResolve: () => _.values(mocks.getDatabaseDefinitions()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/queries/measurements`,
        onSuccess: (databaseDefinitions: IDatabaseDefinition[]) => {
            dispatch(actionsCreators.set(databaseDefinitions));
        },
    })
), 10 * 1000);
