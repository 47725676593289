import _ from "lodash";
import { memoize } from "proxy-memoize";
import { getCurrentLocationNodeId } from "../node/selectors";
import { IFeatureFlagTypes } from "./model";

export const getFeatureFlags = memoize((state: IStoreState) => state.featureFlags?.[getCurrentLocationNodeId(state)]);

export const isFeatureFlagsLoaded = (state: IStoreState): boolean => !_.isEmpty(getFeatureFlags(state));

export const hasRequiredFeatureFlags = (state: IStoreState, neededFeatureFlags: IFeatureFlagTypes[]): boolean => {
    if (process.env.EXEC_MODE == "cmoc") return true; // No feature flags on cmoc for now
    const featureFlags = getFeatureFlags(state);
    if (!featureFlags) return false;
    return _.every(neededFeatureFlags, (f) => featureFlags[f]);
};
