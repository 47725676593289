import { getLocalizedText, MainMenu, t, useIntervalAjaxRequest, useMapState, useMapStateMemoize, usePromisedState, UserModule } from "@comact/crc";
import { CSS, ListRow, PreventBubbleClickContainer, Span, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import tinycolor from "tinycolor2";
import { DashboardsModule } from "..";
import { useDashboardItemData } from "../dashboardsEdits/components/dashboardItemComponent/hooks";
import { getValidDashboardIds } from "../selectors";

const ListRowStyled = styled(ListRow)`
    &.noFavorites {
        padding: 0 12px;
    }
    &.menuItemsContainer {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
    }
`;

export default React.memo(() => {
    const { currentMillNodeId, currentPage } = useMapState((state) => ({ currentMillNodeId: state.currentMillNodeId, currentPage: state.currentPage }));

    useIntervalAjaxRequest((dispatch) => dispatch(DashboardsModule.requests.getDashboardsThrottled(currentMillNodeId)), 30 * 1000);

    const initialFavoritesIds = usePromisedState(async (dispatch, getState) => {
        await dispatch(DashboardsModule.requests.getDashboardsThrottled(currentMillNodeId)).promise;
        await dispatch(UserModule.requests.getMyAccount());
        return Promise.resolve(UserModule.selectors.getCurrentUser(getState())?.favoriteDashboards);
    }, [currentMillNodeId, currentPage]);

    const validFavoriteIds = useMapStateMemoize((state) => (
        _.chain(_.intersection(getValidDashboardIds(state), initialFavoritesIds))
            .map((id) => DashboardsModule.selectors.getDashboardById(state, id))
            .orderBy(({ title }) => _.toLower(getLocalizedText(title).trim()))
            .map(({ id }) => id)
            .value()
    ), [initialFavoritesIds]);

    const currentUser = useMapState((state) => UserModule.selectors.getCurrentUser(state));

    const userCanEditDashboards = useMapState((state) => UserModule.selectors.hasPermission(state, ["dashboards-edit-all"], currentMillNodeId), [currentMillNodeId]);

    return (
        <>
            {currentUser && (
                <>
                    <MainMenu.Title children={t("dashboards.subMenu.favorites")} />
                    {_.isEmpty(validFavoriteIds)
                        ? (
                            <ListRowStyled className="noFavorites">
                                <Span kFontWeight="light" kSize="small">{t("dashboards.subMenu.noFavorites")}</Span>
                            </ListRowStyled>
                        )
                        : (
                            <ListRowStyled kPad="none" kGap="none" className="menuItemsContainer">
                                {_.map(validFavoriteIds, (id) => (
                                    <FavoriteDashboardMenuItem key={id} id={id} millNodeId={currentMillNodeId} />
                                ))}
                            </ListRowStyled>
                        )
                    }
                    <MainMenu.Divider />
                    {userCanEditDashboards && (
                        <MainMenu.MenuItem name="createDashboard" url={`/dashboards/edit/${currentMillNodeId}/new`} children={t("dashboards.subMenu.createDashboard")} />
                    )}
                </>
            )}
            <MainMenu.MenuItem name="showAllDashboards" url={`/dashboards/${currentMillNodeId}`} children={t("dashboards.subMenu.showAllDashboards")} />
        </>
    );
});

const MenuItemStyled = styled(MainMenu.MenuItem)`
    &:hover > .menuItemInner > .text > .preventBubbleClickContainer > :not(.starred) {
        color: ${CSS.colors.greyLight};
        opacity: 1;
    }
    > .menuItemInner {
        > .text {
                display: grid;
                grid-template-columns: minmax(min-content, 1fr) min-content;
                gap: ${CSS.gutter}px;
                > .preventBubbleClickContainer {
                    > :not(.starred) {
                        opacity: 0;
                    }
                    > .starred {
                        color: ${CSS.colors.yellow};
                        &:hover {
                            color: ${tinycolor(CSS.colors.yellow).lighten(15).toString()};
                        }
                    }
                }
            }
    }
`;

const FavoriteDashboardMenuItem = React.memo<{ id: string; millNodeId: string; }>(({ id, millNodeId }) => {
    const { title } = useMapState((state) => DashboardsModule.selectors.getDashboardById(state, id), [id]);
    const { removeFavorite, isFavorite, addFavorite } = useDashboardItemData(id);
    return (
        <MenuItemStyled key={id} name={id} url={`/dashboards/view/${millNodeId}/${id}`} tooltip={getLocalizedText(title, true)}>
            <span>{getLocalizedText(title, true)}</span>
            <PreventBubbleClickContainer className="preventBubbleClickContainer">
                {isFavorite
                    ? <i className="fa fa-star starred" onClick={removeFavorite} title={t("dashboards.buttons.removeFavorite")} />
                    : <i className="far fa-star" onClick={addFavorite} title={t("dashboards.buttons.addFavorite")} />
                }
            </PreventBubbleClickContainer>
        </MenuItemStyled>
    );
});