import { ErrorsPointer, getClassNames, t } from "@comact/crc";
import { FieldContainer } from "@comact/crc/modules/kit";
import _ from "lodash";
import * as React from "react";
import { AbstractCodeEditor } from "../../components/codeEditor";

const titleFormulaVariables = ["$title", "$kpiPattern", "$kpiDefinition", "$dataType", "$unit", "$node", "$parentNode", "$context"];

export const checkTitleFormula = (formula: string): string[] => {
    if (_.isEmpty(formula)) return null;
    const foundVariables = _.isString(formula) ? _.values(formula.match(/\$[A-Z0-9_-]+/gi)) : [];
    if (_.isEmpty(foundVariables)) return [t("widgets.editors.titleFormulaVariableRequired")];
    if (_.some(foundVariables, (variable) => !titleFormulaVariables.includes(variable))) return [t("kpi.alert.errors.formulaInvalid")];
    return null;
};

// replace variables inside the formula with the corresponding numerical values
export const insertVariableValues = (formula: string, values: { [key: string]: string; }): string => (
    _.reduce(titleFormulaVariables, (result, variable) => (
        result.split(variable).join("" + values[variable])
    ), formula || "")
);

interface ITitleFormulaProps {
    onChange: (content: string) => void;
    formula: string;
    errors: string[];
}

export const TitleFormula = React.memo<ITitleFormulaProps>(({ formula, errors, onChange }) => (
    <FieldContainer className={getClassNames([errors && "has-error"])} kPad="none">
        <label>{t("widgets.editors.titleFormula")} <ErrorsPointer errors={errors} /></label>
        <TitleFormulaEditor onChange={onChange} initialValue={formula} variables={titleFormulaVariables} autoValidate oneLine />
    </FieldContainer>
));

class TitleFormulaEditor extends AbstractCodeEditor {
    protected readonly FORMULA_MODE = "kpi-title-formula";
    protected readonly ERROR_KEY_INVALID = "kpi.alert.errors.formulaInvalid";
}