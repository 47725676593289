import { ajaxRequest, getThrottleAjax, store } from "@comact/crc";
import _ from "lodash";
import { API_PREFIX_PRODUCTION_MANAGER } from "../../constants";
import { IReportConfigurationParameterChoices } from "../configurations";
import { IReportTemplate } from "./model";
import { getAllDynamicReportParameterChoices } from "./selectors";
import { actionsCreators } from "./slices";

export const getReportTemplates = getThrottleAjax(() => (dispatch, getState) => (
    ajaxRequest<{}, IReportTemplate[]>({
        serverLessResolve: () => getState().reportTemplates ? _.values(getState().reportTemplates) : import("./mocks").then((m) => m.getReportTemplates()),
        url: `${API_PREFIX_PRODUCTION_MANAGER}/report/templates?limit=9999`,
        onSuccess: (reportTemplates: IReportTemplate[]) => {
            dispatch(actionsCreators.set(reportTemplates));
        },
    })
), 10 * 1000);

export const getDynamicReportParameterChoices = (templateId: string, nodeId: string) => (dispatch: IStoreDispatch) => (
    ajaxRequest({
        url: `${API_PREFIX_PRODUCTION_MANAGER}/report/templates/dynamic-parameters/${templateId}?nodeId=${nodeId}`,
        serverLessResolve: () => (
            store.getState().reportTemplates
                ? getAllDynamicReportParameterChoices(store.getState(), templateId)
                : import("./mocks").then((m) => m.getDynamicReportParameterChoices(templateId))
        ),
        onSuccess: (choices: IReportConfigurationParameterChoices) => {
            dispatch(actionsCreators.setDynamicChoices({ templateId, choices }));
        },
    })
);