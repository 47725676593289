import moment from "moment";

export const documentTypes = ["user-manuals", "service-reports", "technical-drawings", "maintenance-manuals"] as const;
export type IDocumentType = typeof documentTypes[number];

export interface IDocument {
    id: string;
    fileName: string;
    documentType: IDocumentType;
    project?: string; // Maintenance Manuals and Technical drawings only
    domain?: string; // Service Reports only
    lastModificationDate: number;
}

export type IDocuments = Record<string, IDocument>;

export const createDocument = (document: Partial<IDocument>): IDocument => ({
    id: null,
    fileName: null,
    documentType: null,
    lastModificationDate: moment.now(),
    ...document,
});
