import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IFeatureFlagsPerOrganization } from "./model";
import { actionsCreators } from "./slices";

export const getFeatureFlags = getActionAjax((organizationNodeId: string) => (dispatch) => (
    process.env.EXEC_MODE == "icp" && ( // only for icp right now
        ajaxRequest({
            serverLessResolve: () => import("./mocks").then((m) => m.getFeatureFlags()),
            url: `/api/v1/config/flags/${organizationNodeId}`,
            onSuccess: (flags: IFeatureFlagsPerOrganization) => {
                dispatch(actionsCreators.set(flags));
            },
        })
    )
));