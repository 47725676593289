import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IAccessToken, IAccessTokens, IAccessURLs } from "./model";

declare global {
    interface IStoreState {
        accessURLs: IAccessURLs;
        accessTokens: IAccessTokens;
    }
}

const useStatsAccessURLs = "accessURLs";

const accessURLs = createSlice({
    name: "accessURLs",
    initialState: null as IAccessURLs,
    reducers: {
        setAccessURLs: (state, { payload }: PayloadAction<IAccessURLs>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: payload,
                useStats: useStatsAccessURLs,
            })
        ),
    },
});

const useStatsAccessTokens = "accessTokens";

const accessTokens = createSlice({
    name: "accessTokens",
    initialState: null as IAccessTokens,
    reducers: {
        setAccessToken: (state, { payload }: PayloadAction<IAccessTokens>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: payload,
                useStats: useStatsAccessTokens,
            })
        ),
        patchAccessToken: (state, { payload }: PayloadAction<IAccessToken[]>) => (
            Utils.slices.patchWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats: useStatsAccessTokens,
            })
        ),
        deleteAccessToken: (state, { payload }: PayloadAction<string[]>) => (
            Utils.slices.delete({ state, keys: payload, useStats: useStatsAccessTokens })
        ),
    },
});

export const actionsCreators = {
    ...accessURLs.actions,
    ...accessTokens.actions,
};

export default {
    [accessURLs.name]: accessURLs.reducer,
    [accessTokens.name]: accessTokens.reducer,
};