import { usePingUrlUntilReady, useSetInterval } from "@comact/crc";
// eslint-disable-next-line no-restricted-imports
import { getAjaxRequestAuthorization } from "@comact/crc/core/ajaxRequestAuthorizations";
import { CSS, LoadingSpinner, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import * as React from "react";
import videojs from "video.js";

const VideoJsStyled = styled.div`
  // absolutely needed for css to calculate the correct height
  flex-grow: 1;
  display: flex;
  align-content: center;
  align-items: center;
  height: auto;
  width: 100%;
  overflow: hidden;
  > video-js {
    flex-grow: 1;
    display: flex;
    background-color: ${CSS.colors.grey};
    align-content: center;
    align-items: center;
    height: 100%;
    > video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

export const VideoJS = ({ source, type = "application/x-mpegURL", style }: { source: string; type: string; style?: React.CSSProperties; }) => {
    const videoRef = React.useRef<HTMLDivElement>(null);

    const options = React.useMemo(() => ({
        autoplay: "muted",
        controls: true,
        responsive: true,
        children: {
            // Visit https://videojs.com/guides/components/#default-component-tree to check all options.
            controlBar: {
                volumePanel: false,
                playToggle: false,
                pictureInPictureToggle: false,
                liveDisplay: false,
            },
        },
        fluid: false,
        preload: "auto",
        sources: [{
            src: source,
            type,
        }],
    }), [source, type]);

    // get authorization headers to call authenticated API enpoints
    const [requestAuthorizations, setRequestAuthorizations] = React.useState<Record<string, string>>();
    useSetInterval(() => {
        // every 60 seconds check the authorization headers
        getAjaxRequestAuthorization().then(setRequestAuthorizations);
    }, 60 * 1000);

    const urlReady = usePingUrlUntilReady(source, 500);

    React.useEffect(() => {
        if (!urlReady) return () => null;

        // wait until authorization headers is available before creating the player
        if (!requestAuthorizations) return () => null;

        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
        const videoElement = document.createElement("video-js");

        videoElement.classList.add("vjs-big-play-centered");
        videoRef.current.appendChild(videoElement);

        const player = videojs(videoElement, options);
        player.autoplay(options.autoplay);
        player.loadMedia({ src: options.sources }, null);

        // for ICP. add access token to request headers
        if (process.env.EXEC_MODE == "icp") {
            // wait until the player is ready before registering custom hooks
            player.on("xhr-hooks-ready", () => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                player.tech(true).vhs.xhr.onRequest((req: any) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    req.beforeSend = (xhr: any) => {
                        // some requests fail if we add request authorization headers
                        if (!_.includes(xhr.url, "/synchronized-files/camera-storage/")) {
                            _.forEach(requestAuthorizations, (value, key) => {
                                xhr.setRequestHeader(key, value);
                            });
                        }
                    };
                    return req;
                });
            });
        }

        const handleVisibilityChange = () => {
            if (document.hidden && !player.paused()) player.pause();
            else player.play();
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (!player.isDisposed()) {
                player.dispose();
            }
        };
    }, [options, videoRef, requestAuthorizations, source, urlReady]);

    return urlReady ? <VideoJsStyled ref={videoRef} style={style} /> : <LoadingSpinner.Absolute />;
};
