import { ajaxRequest, getActionAjax } from "@comact/crc";
import { IDocuments } from "./model";
import { actionsCreators } from "./slices";

export const getDocuments = getActionAjax((siteId: string) => (dispatch, getState) => (
    ajaxRequest({
        serverLessResolve: () => getState().documents || import("./mocks").then((m) => m.getDocuments()),
        url: `api/v1/resource-library?mill=${siteId}`, // mill can actually be either mill or site (the backend didn't name it correctly)
        onSuccess: (resourceLibrary: IDocuments) => {
            dispatch(actionsCreators.set(resourceLibrary));
        },
    })
));

export const getDownloadLink = getActionAjax((id: string) => () => (
    ajaxRequest({
        serverLessResolve: () => import("./mocks").then((m) => m.getDownloadLink()),
        url: `api/v1/resource-library/link/${id}`, // returns SharePoint anonymous download link with temporary authorization
        onSuccess: (response: { url: string; }) => response.url,
    })
));