import { Icon, UserModule, app, getClassNames, routing, store, t, useBreakpoints, useIntervalAjaxRequest, useMapState, useMapStateMemoize } from "@comact/crc";
// eslint-disable-next-line no-restricted-imports
import InterfacesLauncherModal from "@comact/crc/components/InterfacesLauncherModal";
import { CSS, Card, LoadingSpinner, Title, styled } from "@comact/crc/modules/kit";
import _ from "lodash";
import React from "react";
import { IIepNode, ILocationNode, IMachineCodecNode, IMillNode, NodesModule } from "..";
import Modals from "../../components/Modals";
import "../../dashboards/components/_dashboard.scss";
import { ELearningModule } from "../../elearning";
import { ContextDefinitionsModule } from "../../kpis/contextDefinitions";
import { allPerspectivesDefinitions } from "../perspectives/model";
import { makeGetPerspectiveInstancesForNode } from "../perspectives/selectors";
import * as selectors from "../selectors";
import NodeCard from "./NodeCard";
import NodeMachineKpisCard from "./NodeMachineKpisCard";
import { PageNode, usePageNodeContext } from "./PageNode";

const VerticallyCentered = styled.div`
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    justify-content: center;
    height: 100%;
`;

const breakpoints = [
    { maxWidth: 800, value: "size-400" },
    { maxWidth: 1200, value: "size-800" },
    { maxWidth: 1600, value: "size-1200" },
    { maxWidth: 2000, value: "size-1600" },
    { maxWidth: 2400, value: "size-2000" },
    { maxWidth: Infinity, value: "size-2400" },
];

const PageOverviewStyled = styled(VerticallyCentered)`
    // Since we used display flex instead of display grid, we must remove the gutter from the width (ex.: width: calc(${1 / 10 * 100}% - ${CSS.gutter * 9 / 10}px);)
    &.size-2400 > .centeredContent > * {
        width: 2400px;
        &.quickLinks > *, &.childNodes > * {
            width: calc(${1 / 10 * 100}% - ${CSS.gutter * 9 / 10}px);
        }
        &.machineKpiCards > * {
            width: calc(${1 / 5 * 100}% - ${CSS.gutter * 4 / 5}px);
        }
    }
    &.size-2000 > .centeredContent > * {
        width: 2000px;
        &.quickLinks > *, &.childNodes > * {
            width: calc(${1 / 10 * 100}% - ${CSS.gutter * 9 / 10}px);
        }
        &.machineKpiCards > * {
            width: calc(${1 / 4 * 100}% - ${CSS.gutter * 3 / 5}px);
        }
    }
    &.size-1600 > .centeredContent > * {
        width: 1600px;
        &.quickLinks > *, &.childNodes > * {
            width: calc(${1 / 8 * 100}% - ${CSS.gutter * 7 / 8}px);
        }
        &.machineKpiCards > * {
            width: calc(${1 / 3 * 100}% - ${CSS.gutter * 2 / 3}px);
        }
    }
    &.size-1200 > .centeredContent > * {
        width: 1200px;
        &.quickLinks > *, &.childNodes > * {
            width: calc(${1 / 6 * 100}% - ${CSS.gutter * 5 / 6}px);
        }
        &.machineKpiCards > * {
            width: calc(${1 / 2 * 100}% - ${CSS.gutter * 1 / 2}px);
        }
    }
    &.size-800 > .centeredContent > * {
        width: 800px;
        &.quickLinks > *, &.childNodes > * {
            width: calc(${1 / 4 * 100}% - ${CSS.gutter * 3 / 4}px);
            font-size: .8em;
        }
        &.machineKpiCards > *{
            width: calc(${1 / 2 * 100}% - ${CSS.gutter * 1 / 2}px);
        }
    }
    &.size-400 > .centeredContent > * {
        width: 100%;
        max-width: 400px;
        &.quickLinks > *, &.childNodes > * {
            width: calc(${1 / 2 * 100}% - ${CSS.gutter * 1 / 2}px);
            font-size: .8em;
        }
        &.machineKpiCards > * {
            width: calc(${1 / 1 * 100}% - ${CSS.gutter * 0 / 1}px);
        }
    }
    grid-gap: ${CSS.gutter}px;
    padding: ${CSS.gutter * 2}px 0;

    > .centeredContent {
        padding: ${CSS.gutter}px;
        background-color: ${CSS.colors.greyDarker};
        > .quickLinks, > .machineKpiCards, > .childNodes {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: ${CSS.gutter}px;
        }

        > * + * {
            margin-top: ${CSS.gutter}px !important;
        }
    }
`;

/**
 * Page Overview Node component
 */
const PageOverviewNodes = React.memo(() => {
    const { nodeId, millNodeId } = usePageNodeContext();
    const locationNodeId = useMapState((state) => NodesModule.selectors.getLocationNode(state, nodeId)?.id, [nodeId]);
    const ref = React.useRef<HTMLDivElement>(null);
    const breakpoint = useBreakpoints(ref, breakpoints);

    const node = useMapState((state) => NodesModule.selectors.getNodeById(state, nodeId), [nodeId]);

    const getPerspectives = React.useMemo(() => makeGetPerspectiveInstancesForNode(), []);
    const availablePerspectives = useMapState((state) => getPerspectives({ state, positions: ["overview"], nodeId }), [nodeId]);

    const childNodes = useMapState((state) => (
        _.chain(selectors.getNodesChildrenOfType<(IMachineCodecNode | IMillNode | ILocationNode)>(state, node?.id, ["machineCodec", "location", "millCodec"]))
            .filter(({ templateName }) => process.env.EXEC_MODE == "cmoc" || templateName != "machineCodec") //FIXME: ICP-2440 : remove filter to display machineCodec for icp
            .orderBy(({ name }) => name.toLocaleLowerCase())
            .value()
    ), [node?.id]);

    const isMill = node?.templateName == "millCodec";
    const isLocation = node?.templateName == "location";

    const { canManageUsers, canAccessGrafanaAlerts, canEditSystem, canOpenDiag } = useMapState((state) => ({
        canManageUsers: UserModule.selectors.hasPermission(state, allPerspectivesDefinitions["user-management"].permissions, millNodeId),
        canAccessGrafanaAlerts: UserModule.selectors.hasPermission(state, allPerspectivesDefinitions["notifications/grafana-alerts"].permissions, null),
        canEditSystem: UserModule.selectors.hasPermission(state, ["system-config-get"], millNodeId),
        canOpenDiag: UserModule.selectors.hasPermission(state, ["diagnostic-edit"], millNodeId),
    }), [millNodeId]);

    const onGoToDocumentation = useMapStateMemoize((state) => {
        // Take the IEP documentation if existing or the highest version of any machine
        const machine = _.chain(NodesModule.selectors.getNodesChildrenOfType<(IIepNode | IMachineCodecNode)>(state, state.currentMillNodeId, ["iep", "machineCodec"]))
            .orderBy((n) => [n.templateName == "iep" ? 1 : 0, parseFloat(n.version)])
            .last()
            .value();
        return () => routing.goToExternalUrl(`${machine.host}/manuals/${app.language}`);
    }, []);

    useIntervalAjaxRequest((dispatch) => (
        millNodeId && ContextDefinitionsModule.getRequests(dispatch).getAllContextDefinitions(millNodeId) // needed to be able to find the contexts for the NodeMachineKpisCard components
    ), 20 * 1000, [millNodeId]);

    return (
        <PageOverviewStyled ref={ref} className={breakpoint}>
            {(isMill || isLocation) && (
                <div className="centeredContent">
                    <div className="quickLinks">
                        {_.map(availablePerspectives, (p) => (
                            <React.Fragment key={p.id}>
                                <QuickLinkCard
                                    key={p.id}
                                    href={p.targetBlank ? p.url : routing.createUrl(p.url)}
                                    targetBlank={p.targetBlank}
                                    title={p.name}
                                    icon={p.icon}
                                />
                            </React.Fragment>
                        ))}
                        {canManageUsers && (
                            <QuickLinkCard
                                href="/user-management/users"
                                title={t("core:cmoc.services.userManager")}
                                icon="user-management"
                            />
                        )}
                        <QuickLinkCard href={`/backups/system-backups/${process.env.EXEC_MODE == "icp" ? locationNodeId : nodeId}`} title={t("pages.menu.perspective.backups")} icon="backups" />
                        {process.env.EXEC_MODE == "cmoc" && (
                            <>
                                <QuickLinkCard onClick={Modals.openInterfaceLauncher} title={t("node.interfacesLauncher")} icon="interfaces-launcher" />
                                <QuickLinkCardSorters />
                                {canOpenDiag && <QuickLinkCardDiagnostic />}
                                {canEditSystem && <QuickLinkCardControlCenters />}
                                <QuickLinkCard onClick={onGoToDocumentation} title={t("core:menu.doc")} icon="documentation" />
                            </>
                        )}
                        {process.env.EXEC_MODE == "icp" && (
                            <>
                                {canAccessGrafanaAlerts && (
                                    <QuickLinkCard
                                        href="/notifications/grafana-alerts"
                                        title={t("pages.menu.perspective.notifications")}
                                        icon="notification-management"
                                    />
                                )}
                                <QuickLinkCardELearning />
                                <QuickLinkCard
                                    href={`/resource-library/user-manuals/${locationNodeId}`}
                                    title={t("pages.menu.perspective.resource-library")}
                                    icon="resource-library"
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
            {!_.isEmpty(childNodes) && (
                <div className="centeredContent">
                    {isMill
                        ? (
                            <div className="machineKpiCards">
                                {_.map(childNodes, (childNode) => {
                                    if (childNode.templateName == "machineCodec") {
                                        return <NodeMachineKpisCard key={childNode.id} id={childNode.id} />;
                                    }
                                    return null;
                                })}
                            </div>
                        )
                        : (
                            <div className="childNodes">
                                {_.map(childNodes, (childNode) => {
                                    if (childNode.templateName == "millCodec" || childNode.templateName == "location") {
                                        return <NodeCard key={childNode.id} id={childNode.id} linkToPerspective="overview" view="tile" />;
                                    }
                                    return null;
                                })}
                            </div>
                        )}

                </div>
            )}
        </PageOverviewStyled>
    );
});

/**
 * Page Overview with services
 */
const PageOverviewWithServices = React.memo(() => {
    const ref = React.useRef<HTMLDivElement>(null);
    const breakpoint = useBreakpoints(ref, breakpoints);

    const { services, currentMachine, hasInterfacesLauncher } = useMapState((state) => ({
        currentMachine: state.currentMachine,
        services: _.filter(state.services, (s) => ["WEB_APPLICATION", "CONFIGURATION"].includes(s.category)),
        hasInterfacesLauncher: state.services.some((s) => s.type == "interfaces-launcher"),
    }));

    return (
        <PageOverviewStyled ref={ref} className={breakpoint}>
            <div className="centeredContent">
                <div className="quickLinks">
                    {services.map((s) => (
                        <QuickLinkCard
                            key={s.name}
                            href={s.remote ? `http://${s.host}${s.url}` : routing.createExternalUrl(s.url)}
                            title={t("core:" + s.name)}
                            icon={s.type as React.ComponentProps<typeof Icon>["type"]}
                        />
                    ))}
                    <QuickLinkCard
                        href={routing.createExternalUrl(`/manuals/${app.language}`)}
                        title={t("core:menu.doc")}
                        icon="documentation"
                    />
                    {hasInterfacesLauncher &&
                        <InterfaceLauncherCard host={currentMachine.host} name={currentMachine.name} model={currentMachine.model as IMachineCodecNode["machine"]} />
                    }
                </div>
            </div>
        </PageOverviewStyled>
    );
});

/**
 * Page Overview
 */
const PageOverview = React.memo(() => {
    const hasNodes = useMapState((state) => state.system.isMaster && state.system.hasTwin);
    return hasNodes ? <PageOverviewNodes /> : <PageOverviewWithServices />;
});

/**
 * Page overview Redirect
 */
export const PageOverviewRedirect = React.memo(() => {
    React.useEffect(() => {
        correctHomeUrl().then((url) => {
            routing.goToUrl(url.slice(routing.baseUrl.length));
        });
    }, []);

    return <LoadingSpinner.Absolute />;
});

export const correctHomeUrl = (() => {
    let getCount = 0;
    return async () => {
        let skipLoadNodes = true;
        while (skipLoadNodes) {
            skipLoadNodes = process.env.EXEC_MODE == "icp" && !UserModule.selectors.getCurrentUser(store.getState()); // Don't try to load nodes on icp before having the user
            if (skipLoadNodes) await new Promise((r) => setTimeout(r, 5000)); // wait before next loop
        }
        while (_.size(store.getState().nodes) == 0 && ++getCount < 5) { // call the node api until we get have some nodes
            try {
                await NodesModule.getRequests(store.dispatch).fetchAllNodes().promise;
            } catch {
                await new Promise((r) => setTimeout(r, 5000)); // wait before next loop
            }
        }

        if (process.env.EXEC_MODE == "icp") {
            const organizationNodeIds = _.map(NodesModule.selectors.getNodesByTemplateName(store.getState(), "customer"), ({ id }) => id);
            if (_.isEmpty(organizationNodeIds)) throw new Error("No organization node found");
            const locationNodeIds = _.map(NodesModule.selectors.getNodesByTemplateName(store.getState(), "location"), ({ id }) => id);
            if (_.size(locationNodeIds) == 1) routing.homeUrl = routing.createUrl(`/overview/${_.values(locationNodeIds)[0]}`);
            else routing.homeUrl = routing.createUrl(`/overview/${organizationNodeIds[0]}`); // at this point there should always be exactly 1 organization
        } else {
            const millNodeIds = _.map(NodesModule.selectors.getNodesByTemplateName(store.getState(), "millCodec"), ({ id }) => id);
            routing.homeUrl = routing.createUrl(`/overview/${_.values(millNodeIds)[0]}`); // at this point there should always be exactly 1 mill
        }
        return routing.homeUrl;
    };
})();

const QuickLinkCardStyled = styled(Card)`
    position: relative;
    height: 10em;
    text-align: center;
    display: grid;
    grid-template-rows: min-content 1fr;

    > header {
        line-height: 1;
    }
    > .service-icon {
        opacity: .7;
        display: grid;
        transition: opacity .3s ease-in-out;
        align-items: center;
        justify-content: center;
        > i {
            font-size: 4em;
        }
        > .svg-icon {}
    }

    > ${LoadingSpinner.Inline} {
        position: absolute;
        top: ${CSS.gutter}px;
        right: ${CSS.gutter}px;
    }
    &.disabled {
        pointer-events: none;
        > header {
            opacity: 0.5;
        }
        > .service-icon {
            opacity: 0.2;
        }
    }
`;

const QuickLinkCard = React.memo(({ onClick, href, title, icon, targetBlank, disabled = false, loading = false }: {
    title: string;
    href?: string;
    icon: React.ComponentPropsWithoutRef<typeof Icon>["type"];
    targetBlank?: boolean;
    onClick?: () => unknown;
    disabled?: boolean;
    loading?: boolean;
}) => (
    <QuickLinkCardStyled
        className={getClassNames([disabled && "disabled"])}
        onClick={!disabled ? onClick : undefined}
        href={!disabled ? href : undefined}
        target={targetBlank ? "_blank" : "_self"}
    >
        <header children={<Title>{title}</Title>} />
        <div className="service-icon"><Icon type={icon} /></div>
        {loading && <LoadingSpinner.Inline />}
    </QuickLinkCardStyled>
));

const InterfaceLauncherCard = React.memo<{ host: string; name: string; model: IMachineCodecNode["machine"]; }>(({ host, name, model }) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const openDialog = React.useCallback(() => setShowDialog(true), []);
    const closeDialog = React.useCallback(() => setShowDialog(false), []);

    return (
        <>
            <QuickLinkCard onClick={openDialog} title={t("node.interfacesLauncher")} icon="interfaces-launcher" />
            <InterfacesLauncherModal
                host={host}
                name={name}
                model={model}
                isActive={showDialog}
                onHideCallback={closeDialog}
            />
        </>
    );
});

const QuickLinkCardELearning = React.memo(() => {
    const data = useMapState((state) => state.elearning);
    useIntervalAjaxRequest((dispatch) => dispatch(ELearningModule.requests.getElearningLinks()), 10 * 60 * 1000);

    const singleLink = data?.sharepointLinks?.length == 1;
    return (
        <QuickLinkCard
            href={singleLink ? data.sharepointLinks[0].url : undefined}
            targetBlank
            onClick={!singleLink ? Modals.openElearningLauncher : undefined}
            title={t("elearning.title")}
            icon="graduation-cap"
            loading={!data}
            disabled={!data}
        />
    );
});

const QuickLinkCardSorters = React.memo(() => {
    const data = useMapStateMemoize((state) => {
        const sorters = _.chain(NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec"]))
            .filter((n) => n.sorterEnabled)
            .map((n) => ({
                name: n.name,
                url: `${document.location.protocol}//${n?.host}/sorter`,
            }))
            .value();
        if (_.isEmpty(sorters)) {
            return null;
        } else if (_.size(sorters) == 1) {
            return { href: _.first(_.values(sorters)).url, sorters };
        } else {
            return { open: Modals.openSorters, sorters };
        }
    });

    return data && (
        <QuickLinkCard
            onClick={data.open}
            href={data.href}
            title={t("core:cmoc.services.sorter")}
            icon="sorter"
        />
    );
});

const QuickLinkCardDiagnostic = React.memo(() => {
    const data = useMapStateMemoize((state) => {
        const diagnostics = _.chain(NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec"]))
            .filter((n) => n.diagnosticEnabled)
            .map((n) => ({
                name: n.name,
                url: `${document.location.protocol}//${n?.host}/diagnostic`,
            }))
            .value();
        if (_.isEmpty(diagnostics)) {
            return null;
        } else if (_.size(diagnostics) == 1) {
            return { href: _.first(_.values(diagnostics)).url, diagnostics };
        } else {
            return { open: Modals.openDiagnostics, diagnostics };
        }
    });

    return data && (
        <QuickLinkCard
            onClick={data.open}
            href={data.href}
            title={t("core:cmoc.services.diagnostic")}
            icon="diagnostic"
        />
    );
});

const QuickLinkCardControlCenters = React.memo(() => {
    const data = useMapStateMemoize((state) => {
        const controlCenters = NodesModule.selectors.getNodesChildrenOfTypeRecursive<IMachineCodecNode>(state, state.currentMillNodeId, ["machineCodec", "iep"]);
        if (_.isEmpty(controlCenters)) {
            return null;
        } else if (_.size(controlCenters) == 1) {
            const node = _.first(_.values(controlCenters));
            return { href: routing.createUrl(`${document.location.protocol}//${node.host}/control-center`), controlCenters };
        } else {
            return { open: Modals.openControlCenters, controlCenters };
        }
    });

    return data && (
        <QuickLinkCard
            onClick={data.open}
            href={data.href}
            title={t("core:cmoc.services.controlCenter")}
            icon="control-center"
        />
    );
});

export default () => (
    <PageNode perspective="overview">
        <PageOverview />
    </PageNode>
);