import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import {
    IZoneConfiguration, IZoneConfigurationActivationStatus, IZoneConfigurationAdvanced, IZoneConfigurations, IZoneConfigurationsActivationStatus, IZoneConfigurationsAdvanced, IZoneTemplate,
    IZoneTemplates
} from "./model";

declare global {
    interface IStoreState {
        zoneConfigurationsBasic: IZoneConfigurations;
        zoneConfigurationsAdvanced: IZoneConfigurationsAdvanced;
        zoneConfigurationsActivationStatus: IZoneConfigurationsActivationStatus;
        zoneConfigurationsTemplates: IZoneTemplates;
    }
}

const zoneConfigurationsBasic = createSlice({
    name: "zoneConfigurationsBasic",
    initialState: null as IZoneConfigurations,
    reducers: {
        patchBasic: (state, { payload }: PayloadAction<IZoneConfiguration>) => (
            Utils.slices.patchWithoutEqualCheck({
                state,
                nextState: { [payload.nodeId]: payload },
                useStats: "zoneConfigurationsBasic",
            })
        ),
    },
});

const zoneConfigurationsAdvanced = createSlice({
    name: "zoneConfigurationsAdvanced",
    initialState: null as IZoneConfigurationsAdvanced,
    reducers: {
        patchAdvanced: (state, { payload }: PayloadAction<{ advancedConfig: IZoneConfigurationAdvanced; nodeId: string; }>) => (
            Utils.slices.patchWithoutEqualCheck({
                state,
                nextState: { [payload.nodeId]: payload.advancedConfig },
                useStats: "zoneConfigurationsAdvanced",
            })
        ),
    },
});

const zoneConfigurationsActivationStatus = createSlice({
    name: "zoneConfigurationsActivationStatus",
    initialState: null as IZoneConfigurationsActivationStatus,
    reducers: {
        patchStatus: (state, { payload }: PayloadAction<{ status: IZoneConfigurationActivationStatus; nodeId: string; }>) => (
            Utils.slices.patchWithoutEqualCheck({
                state,
                nextState: { [payload.nodeId]: payload.status },
                useStats: "zoneConfigurationsActivationStatus",
            })
        ),
    },
});

const zoneConfigurationsTemplates = createSlice({
    name: "zoneConfigurationsTemplates",
    initialState: null as IZoneTemplates,
    reducers: {
        setTemplates: (state, { payload }: PayloadAction<IZoneTemplate[]>) => (
            Utils.slices.setWithoutEqualCheck({
                state,
                nextState: _.keyBy(payload, ({ id }) => id),
                useStats: "zoneConfigurationsTemplates",
            })
        ),
    },
});

export const actionsCreators = {
    ...zoneConfigurationsBasic.actions,
    ...zoneConfigurationsAdvanced.actions,
    ...zoneConfigurationsActivationStatus.actions,
    ...zoneConfigurationsTemplates.actions,
};

export default {
    [zoneConfigurationsBasic.name]: zoneConfigurationsBasic.reducer,
    [zoneConfigurationsAdvanced.name]: zoneConfigurationsAdvanced.reducer,
    [zoneConfigurationsActivationStatus.name]: zoneConfigurationsActivationStatus.reducer,
    [zoneConfigurationsTemplates.name]: zoneConfigurationsTemplates.reducer,
};