import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IDatabaseDefinition, IDatabaseDefinitions } from "./model";

declare global {
    interface IStoreState {
        databaseDefinitions: IDatabaseDefinitions;
    }
}
const useStats = "databaseDefinitions";
const isEqual = (a: IDatabaseDefinition, b: IDatabaseDefinition) => a.modificationStamp == b.modificationStamp;

const databaseDefinitions = createSlice({
    name: "databaseDefinitions",
    initialState: {} as IDatabaseDefinitions,
    reducers: {
        set: (state, { payload }: PayloadAction<IDatabaseDefinition[]>) => (
            Utils.slices.set({
                state,
                nextState: _.keyBy([
                    ...payload,
                    { // The back never sends us this since it isn't really a database type, but we still want the option to be displayed
                        id: "kpi",
                        collection: false,
                        fields: null,
                        tags: null,
                        modificationStamp: null,
                    },
                ], ({ id }) => id),
                isEqual,
                useStats,
            })
        ),
    },
});

export const actionsCreators = {
    ...databaseDefinitions.actions,
};

export default {
    [databaseDefinitions.name]: databaseDefinitions.reducer,
};