import _ from "lodash";
import { IReportTemplates } from "./model";

export const getReportTemplates =
    ({ reportTemplates }: IStoreState): IReportTemplates => reportTemplates;

export const getAllDynamicReportParameterChoices = ({ reportTemplates }: IStoreState, templateId: string) => (
    _.chain(reportTemplates[templateId].parameters)
        .filter(({ isDynamic }) => isDynamic)
        .flatMap(({ choices }) => choices)
        .value()
);