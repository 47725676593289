import { Utils } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { IReportConfigurationParameterChoices } from "../configurations";
import { IReportTemplate, IReportTemplates } from "./model";

declare global {
    interface IStoreState {
        reportTemplates: IReportTemplates;
    }
}

const useStats = "reportTemplates";

const reportTemplates = createSlice({
    name: "reportTemplates",
    initialState: null as IReportTemplates,
    reducers: {
        set: (state, { payload }: PayloadAction<IReportTemplate[]>) => {
            const fixedReportTemplates: IReportTemplate[] = _.forEach(payload, (reportTemplate) => {
                _.forEach(reportTemplate.parameters, (parameter) => {
                    if (!parameter.choices && !parameter.cascading) parameter.isDynamic = true;
                });
            });
            return Utils.slices.setWithoutEqualCheck({ state, nextState: _.keyBy(fixedReportTemplates, ({ id }) => id), useStats });
        },
        setDynamicChoices: (state, { payload }: PayloadAction<{ templateId: string; choices: IReportConfigurationParameterChoices; }>) => {
            const { templateId, choices } = payload;
            const template = state[templateId];
            if (!template) return state;
            _.forEach(template.parameters, (parameter) => {
                if ((parameter.isDynamic || parameter.cascading) && choices[parameter.name]) {
                    parameter.choices = choices[parameter.name];
                }
            });
            return Utils.slices.patchWithoutEqualCheck({ state, nextState: { [template.id]: template }, useStats });
        },
    },
});

export const actionsCreators = {
    ...reportTemplates.actions,
};

export default {
    [reportTemplates.name]: reportTemplates.reducer,
};