import { deepImmerUpdate } from "@comact/crc";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IGrafanaIntegration } from "./model";

declare global {
    interface IStoreState {
        grafanaIntegration: IGrafanaIntegration;
    }
}

const grafanaIntegration = createSlice({
    name: "grafanaIntegration",
    initialState: null as IGrafanaIntegration,
    reducers: {
        set: (state, { payload }: PayloadAction<IGrafanaIntegration>) => deepImmerUpdate(state, payload),
    },
});

export const actionsCreators = {
    ...grafanaIntegration.actions,
};

export default {
    [grafanaIntegration.name]: grafanaIntegration.reducer,
};