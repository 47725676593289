import { DateFromNow, UserModule, app, getClassNames, routing, t, useMapState } from "@comact/crc";
// eslint-disable-next-line no-restricted-imports
import InterfacesLauncherModal from "@comact/crc/components/InterfacesLauncherModal";
import { CSS, Card, FastMenu, Header, Line, Main, Span, Title, styled } from "@comact/crc/modules/kit";
import * as React from "react";
import tinycolor from "tinycolor2";
import { INode } from "../";
import { CameraImage } from "../../cameraLiveStream/components/CameraVideo";
import { usePageNodeContext } from "../hooks";
import { IPerspective } from "../perspectives/model";
import * as selectors from "../selectors";
import Status, { StatusConnexion } from "./Status";

const CardStyled = styled(Card)`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    > ${Header} {
        display: grid;
        grid-template-columns: 1fr min-content min-content;
        grid-column-gap: ${CSS.gutter}px;
        align-items: center;

        > ${Title} {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        > .statusContainer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
        }
    }

    > ${Main} {
        display: grid;
        grid-template-columns: minmax(min-content, 30%) 1fr;
        grid-column-gap: ${CSS.gutter}px;

        > .imgContainer {
            display: grid;
            align-items: center;
            padding: ${CSS.gutter}px;
            text-align: center;
            background-color: ${tinycolor(CSS.colors.grey).darken(20).setAlpha(0.2).toRgbString()};

            > .fa {
                font-size: 60px;
                color: ${tinycolor(CSS.colors.white).darken(20).toRgbString()}
            }

            > img {
                width: 100%;
                &[src$=".svg"] {
                    opacity: .7;
                }
            }

            &.view-tile {
                background-color: transparent;
                > .fa {
                    font-size: 80px;
                }
            }
        }
        > .content {
            display: grid;
            grid-template-rows: 1fr min-content ;
            grid-column-gap: ${CSS.gutter}px;
        }
    }

    &.view-tile {
        > ${Header} {
            display: grid;
            grid-template-columns: 1fr;
            text-align: center;
        }
        > ${Main} {
            display: grid;
            grid-template-columns: 1fr;
        }
    }
`;

interface INodeCard {
    id: string;
    searchExtract?: string | JSX.Element;
    linkToPerspective?: IPerspective;
    view?: "tile" | "normal";
    showFastMenu?: boolean;
    noClick?: boolean;
    showStatuses?: boolean;
}

const NodeCard = React.memo<INodeCard>(({ id, searchExtract, linkToPerspective = "details", view = "normal", showFastMenu = true, noClick = false, showStatuses = false }) => {
    const node = useMapState((state) => selectors.getNodeById(state, id), [id]);

    const onClick = React.useCallback(() => {
        app.goToUrl(`/${linkToPerspective}/${id}`);
    }, [id, linkToPerspective]);

    if (!node) return null;
    const { name, templateName, templateVersion, modificationDate } = node;

    return (
        <CardStyled className={getClassNames([node.templateName, `view-${view}`])} onClick={noClick ? undefined : onClick} data-test={node.id}>
            {showFastMenu &&
                <DropDownView node={node} />
            }
            <Header kPad="squareHalf">
                <Title title={`${name} - ${templateName}`}>
                    <span>{searchExtract || name}</span>
                </Title>
                {view != "tile" && (
                    <>
                        {showStatuses && (
                            <div className="statusContainer">
                                <StatusConnexion id={node.id} design="mini" showChildren />
                                <Status id={node.id} showChildren onlyShowChildren />
                            </div>
                        )}
                    </>
                )}
            </Header>
            <Main kPad="squareHalf">
                <div className={getClassNames(["imgContainer", `view-${view}`])}>
                    {(() => {
                        switch (node.templateName) {
                            case "customer": return <i className="fa fa-sitemap" />;
                            case "location": return <i className="fa fa-map-location-dot" />;
                            case "millCodec": return <i className="fa fa-industry-windows" />;
                            case "iep": return <i className="fa fa-computer" />;
                            case "machineCodec": return <img src={`${routing.baseUrl}/static/machines/${node.machine?.toUpperCase() || "UNKNOWN"}.png`} />;
                            case "scannerCodec": return <img src={`${routing.baseUrl}/static/nodes/scannerCodec.svg`} />;
                            case "L5X": return <img src={`${routing.baseUrl}/static/nodes/L5X.svg`} />;
                            case "machineComponent": return <i className="fa fa-cogs" />;
                            case "pickAndPlace": return <i className="fa fa-conveyor-belt-arm" />;
                            case "smartVisionZone":
                            case "standaloneCamera":
                                return <i className="fa fa-camera-cctv" />;
                            case "cameraServer": return <i className="fa fa-server" />;
                            case "camera": return process.env.EXEC_MODE == "icp"
                                ? <i className="fa fa-camera-cctv" /> // we can't see the camera video on icp for now
                                : <CameraImage nodeId={node.id} height={50} refreshInterval={60 * 1000} />;
                            default: return <i className="fa fa-cog" />;
                        }
                    })()}
                </div>
                {view != "tile" && (
                    <div className="content">
                        <div className="top">
                            <Line>
                                <Span kFontWeight="bold">{t(`node.template.${templateName}`)}</Span> <Span kFontWeight="light">{templateVersion}</Span>
                            </Line>
                            {(node.templateName == "machineCodec" || node?.templateName == "iep") && (
                                <Line>
                                    <Span kFontWeight="light"><i>{node.host}</i></Span>
                                    {node.version && (
                                        <Span kFontWeight="bold" kSize="small">{" "}({node.version})</Span>
                                    )}
                                </Line>
                            )}
                            <Line kFontWeight="light" kColor="light">{t("node.modificationDateLabel")} <DateFromNow timestamp={modificationDate} /></Line>
                        </div>
                    </div>
                )}
            </Main>
        </CardStyled>
    );
});

export default NodeCard;

const DropDownView = React.memo<{ node: INode; }>(({ node }) => {
    const [showDialog, setShowDialog] = React.useState(false);
    const openDialog = React.useCallback(() => setShowDialog(true), []);
    const closeDialog = React.useCallback(() => setShowDialog(false), []);
    const { millNodeId } = usePageNodeContext();

    const InterfacesLauncherDialogJSX = React.useMemo(() => {
        if (node.templateName != "machineCodec") return null;
        return (
            <InterfacesLauncherModal
                host={node.host}
                name={node.name}
                model={node.machine}
                isActive={showDialog}
                onHideCallback={closeDialog}
            />
        );
    }, [node, showDialog, closeDialog]);

    const dropdown = useMapState((state) => {
        if (!node) return null;
        const menuItems: React.ComponentProps<typeof FastMenu.Component>["dropdown"]["menuItems"] = [];
        if (process.env.EXEC_MODE == "cmoc") {
            if (node?.templateName == "machineCodec") {
                if (node?.sorterEnabled) {
                    menuItems.push({
                        label: <>{t("core:cmoc.services.sorter")}</>,
                        onClick: () => routing.goToExternalUrl("/sorter"),
                    });
                }
                if (node?.diagnosticEnabled) {
                    menuItems.push({
                        label: <>{t("core:cmoc.services.diagnostic")}</>,
                        onClick: () => routing.goToExternalUrl("/diagnostic"),
                    });
                }
                menuItems.push({
                    label: <>{t("node.interfacesLauncher")}</>,
                    onClick: openDialog,
                });
            }
            if ((node?.templateName == "machineCodec" || node?.templateName == "iep")) {
                menuItems.push({
                    label: <>{t("core:cmoc.services.controlCenter")}</>,
                    onClick: () => routing.goToExternalUrl(`${document.location.protocol}//${node.host}/control-center`),
                });
                if (UserModule.selectors.hasPermission(state, ["system-config-edit"], millNodeId)) {
                    menuItems.push({
                        label: <>{t("core:cmoc.services.cmocConfiguration")}</>,
                        onClick: () => routing.goToExternalUrl(`${document.location.protocol}//${node.host}/control-center/configuration/machine`),
                    });
                }
                menuItems.push({
                    label: <>{t("core:menu.doc")}</>,
                    onClick: () => routing.goToExternalUrl(`/manuals/${app.language}`),
                });
            }
        }
        return { menuItems };
    }, [node, openDialog, millNodeId]);

    const dropdownJSX = React.useMemo(() => (
        <FastMenu.Component dropdown={dropdown} position="bottomRight" keepVisible />
    ), [dropdown]);

    return (
        <>
            {process.env.EXEC_MODE != "icp" && dropdownJSX}
            {process.env.EXEC_MODE != "icp" && InterfacesLauncherDialogJSX}
        </>
    );
});